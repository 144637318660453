
import { Fragment, useState, memo } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';
import Logo from './../assist/triokzzlohgo.svg';
const navigation = {
    categories: [
        {
            id: 'womens',
            name: 'Women',
            featured: [
                {
                    name: 'New Arrivals',
                    href: '/product/womens',
                    imageSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JI-W-115-119.png",
                    imageAlt: 'Models sitting back to back, wearing Basic T Shirt in black and bone.',
                },
                {
                    name: 'Basic Tees',
                    href: '/product/womens',
                    imageSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JT-W-69-74.png",
                    imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
                },
            ],
            sections: [
                {
                    id: 'clothing',
                    name: 'Clothing',
                    items: [
                        { name: 'Unisex Tshirt', href: '/product/womens' },
                        { name: 'Solid Tshirt', href: '/product/womens' }
                        // { name: 'Pants', href: '#' },
                        // { name: 'Denim', href: '#' },
                        // { name: 'Sweaters', href: '#' },
                        // { name: 'T-Shirts', href: '/product/womens' },
                        // { name: 'Jackets', href: '#' },
                        // { name: 'Activewear', href: '#' },
                        // { name: 'Browse All', href: '#' },
                    ],
                }
            ],
        },
        {
            id: 'mens',
            name: 'Men',
            featured: [
                {
                    name: 'New Arrivals',
                    href: '/product/mens',
                    imageSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/Round-neck-model-JI-T-120-124.webp",
                    imageAlt: 'Drawstring top with elastic loop unisex tshirt',
                }
                // {
                //     name: 'Artwork Tees',
                //     href: '#',
                //     imageSrc: "https://rukminim2.flixcart.com/image/416/416/xif0q/wall-decoration/w/p/x/metal-wall-decor-wall-arts-for-home-living-room-hotel-decoration-original-imagqhzbtq8qhy4s.jpeg?q=70",
                //     imageAlt:
                //         'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
                // },
            ],
            sections: [
                {
                    id: 'clothing',
                    name: 'Clothing',
                    items: [
                        // { name: 'Pants', href: '#' },
                        // { name: 'Sweaters', href: '#' },
                        { name: 'T-Shirts', href: '/product/mens' },
                        { name: 'Solid T-shirts', href: '/product/mens' },
                        // { name: 'Activewear', href: '#' },
                        // { name: 'Browse All', href: '#' },
                    ],
                },
                {
                    id: 'accessories',
                    name: 'Accessories',
                    items: [
                        // { name: 'Watches', href: '#' },
                        // { name: 'Wallets', href: '#' },
                        { name: 'Bags', href: '/product/Home%20Decor%20&%20Temple' },
                        // { name: 'Sunglasses', href: '#' },
                        // { name: 'Hats', href: '#' },
                        // { name: 'Belts', href: '#' },
                    ],
                },
                {
                    id: 'brands',
                    name: 'Brands',
                    items: [
                        { name: 'Re-Arranged', href: '#' },
                        { name: 'Counterfeit', href: '#' },
                        { name: 'Full Nelson', href: '#' },
                        { name: 'My Way', href: '#' },
                    ],
                },
            ],
        },
        {
            id: 'home decor',
            name: 'Home Decor',
            featured: [
                {
                    name: 'New Arrivals',
                    href: '/product/Home%20Decor%20&%20Temple',
                    imageSrc: "https://rukminim2.flixcart.com/image/416/416/xif0q/wall-decoration/w/p/x/metal-wall-decor-wall-arts-for-home-living-room-hotel-decoration-original-imagqhzbtq8qhy4s.jpeg?q=70",
                    imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
                },
                {
                    name: 'Basic Tees',
                    href: '/product/Home%20Decor%20&%20Temple',
                    imageSrc: "https://rukminim2.flixcart.com/image/416/416/xif0q/wall-decoration/w/p/x/metal-wall-decor-wall-arts-for-home-living-room-hotel-decoration-original-imagqhzbtq8qhy4s.jpeg?q=70",
                    imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
                },
            ],
            sections: [
                {
                    id: 'decore',
                    name: 'decor',
                    items: [
                        { name: 'Shisha pot', href: '/product/Home%20Decor%20&%20Temple' },
                        { name: 'copper glass', href: '/product/Home%20Decor%20&%20Temple' },
                        { name: 'coco bowl', href: '/product/Home%20Decor%20&%20Temple' },
                        { name: 'drawing', href: '/product/Home%20Decor%20&%20Temple' },
                        { name: 'painting', href: '/product/Home%20Decor%20&%20Temple' },
                        { name: 'checkinD', href: '/signin' }
                    ],
                }
            ],
        }
    ],
    pages: [
        { name: 'Company', href: '#' },
        { name: 'Stores', href: '#' },
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ProductNav(props) {
    const [open, setOpen] = useState(false)
    const [searchinput, setSearchinput] = useState("")

    const navigate = useNavigate()
    const onSearchinput = (val) => {
        setSearchinput(val)
    }

    return (
        <div className="sticky top-0 z-20 bg-white">
            {/* Mobile menu */}
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                                <div className="flex px-4 pb-2 pt-5">
                                    <button
                                        type="button"
                                        className="relative -m-2 inline-flex items-center justify-center bg-red rounded-md p-2 text-gray-400"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                <Tab.Group as="div" className="mt-2">
                                    <div className="border-b border-gray-200">
                                        <Tab.List className="-mb-px space-x-8 px-4">
                                            {navigation.categories.map((category) => (
                                                <Tab
                                                    key={category.name}
                                                    className={({ selected }) =>
                                                        classNames(
                                                            selected ? 'border-indigo-600 text-indigo-600' : 'border-transparent text-gray-900',
                                                            'flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium'
                                                        )
                                                    }
                                                    onClick={() => props.onSelectNavItem(category.id)}
                                                >
                                                    {category.name}
                                                </Tab>
                                            ))}
                                        </Tab.List>
                                    </div>
                                    <Tab.Panels as={Fragment}>
                                        {navigation.categories.map((category) => (
                                            <Tab.Panel key={category.name} className="space-y-10 px-4 pb-8 pt-10">
                                                <div className="grid grid-cols-2 gap-x-4">
                                                    {category.featured.map((item) => (
                                                        <div key={item.name} className="group relative text-sm">
                                                            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                                                <img src={item.imageSrc} alt="Solid T-shirt,Plain T-Shirt,Buy Plain Solid T-shirts,printo mug, Bewakoofy items, home decor items, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags" className="object-cover object-center" />
                                                            </div>
                                                            <a href={item.href} className="mt-6 block font-medium text-gray-900">
                                                                <span className="absolute inset-0 z-10" aria-hidden="true" />
                                                                {item.name}
                                                            </a>
                                                            <p aria-hidden="true" className="mt-1">
                                                                Shop now
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                                {category.sections.map((section) => (
                                                    <div key={section.name}>
                                                        <p id={`${category.id}-${section.id}-heading-mobile`} className="font-medium text-gray-900">
                                                            {section.name}
                                                        </p>
                                                        <ul
                                                            role="list"
                                                            aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                                                            className="mt-6 flex flex-col space-y-6"
                                                        >
                                                            {section.items.map((item) => (
                                                                <li key={item.name} className="flow-root">
                                                                    <a href={item.href} className="-m-2 block p-2 text-gray-500">
                                                                        {item.name}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ))}
                                            </Tab.Panel>
                                        ))}
                                    </Tab.Panels>
                                </Tab.Group>


                                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                                    <div className="flow-root">
                                        <ul className="items-center mb-6 text-sm font-medium text-gray-900 sm:mb-0 dark:text-white">
                                            <li className="mr-4 hover:underline md:mr-6 ">
                                                <a href="/grocery/products"> Grocery</a>
                                            </li>
                                            <li className="mr-4 hover:underline md:mr-6 ">
                                                <a href="/watchtv"> WatchTv</a>
                                            </li>

                                            <li className="mr-4 hover:underline md:mr-6 ">
                                                <a href="/product/Home%20Decor%20&%20Temple">Home and temple Decor</a>
                                            </li>
                                            <li className="mr-4 hover:underline md:mr-6 ">
                                                <a href="/about">
                                                    About
                                                </a>
                                            </li>
                                            <li className="mr-4 hover:underline md:mr-6">
                                                <a href="/privacy">
                                                    Privacy
                                                </a>
                                            </li>
                                            <li className="mr-4 hover:underline md:mr-6 " onClick={() => navigate("/contact")}>
                                                Contact
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div className="flow-root">
                                        <a href="#" className="-m-2 block p-2 font-medium text-gray-900">
                                            Create account
                                        </a>
                                    </div> */}
                                </div>

                                <div className="border-t border-gray-200 px-4 py-6">
                                    <a href="#" className="-m-2 flex items-center p-2">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png"
                                            alt="Solid T-shirt,Plain T-Shirt,Buy Plain Solid T-shirts,printo mug, Bewakoofy items, home decor items, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags"
                                            className="block h-auto w-5 flex-shrink-0"
                                            title="best cultural artwork"
                                        />
                                        <span className="ml-3 block text-base font-medium text-gray-900">IND</span>
                                        <span className="sr-only">, change currency</span>
                                    </a>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            <header className="relative bg-white-50 text-gray-900">
                {/* <div className="ml-4 flex lg:ml-0">
                    <a href="/">
                        <span className="sr-only">Shopify</span>
                        <img
                            className="h-8 w-auto"
                            // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                            alt="Shopify"
                        />
                    </a>
                </div> */}
                {/* <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
                    All  product price are tax included
                </p> */}

                <nav aria-label="Top" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="border-b border-gray-200">
                        <div className="flex h-10 items-center">
                            <button
                                type="button"
                                className="relative rounded-md bg-white p-1 text-gray-400 lg:hidden"
                                onClick={() => setOpen(true)}
                            >
                                <span className="absolute -inset-0.5" />
                                <span className="sr-only">Open menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>

                            {/* Logo */}
                            <div className="ml-1 flex lg:ml-0">
                                {/* <a href="/"> */}
                                <span className="sr-only">shoptodecore</span>
                                <img
                                    className="h-14 w-25"
                                    src={Logo}
                                    alt="Solid T-shirt,Plain T-Shirt,Buy Plain Solid T-shirts,printo mug, Bewakoofy items, home decor items, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags"
                                />
                                {/* </a> */}
                            </div>

                            {/* Flyout menus */}
                            {/* <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                                <div className="flex h-full space-x-8">
                                    {navigation.categories.map((category) => (
                                        <Popover key={category.name} className="flex">
                                            {({ open }) => (
                                                <>
                                                    <div className="relative flex">
                                                        <Popover.Button
                                                            className={classNames(
                                                                open
                                                                    ? 'border-indigo-600 text-indigo-600'
                                                                    : 'border-transparent text-gray-700 hover:text-gray-800',
                                                                'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out'
                                                            )}
                                                            onClick={() => props.onSelectNavItem(category.id)}
                                                        >
                                                            {category.name}
                                                        </Popover.Button>
                                                    </div>
                                                </>
                                            )}
                                        </Popover>
                                    ))}


                                </div>
                            </Popover.Group> */}

                            <div className="ml-auto flex items-center">
                                <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                                    {/* <a href="/login" className="text-sm font-medium text-gray-700 hover:text-gray-800"> */}
                                    <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-900 sm:mb-0 dark:text-white">

                                        <li className="mr-4 hover:underline md:mr-6 ">
                                            <a href="/product/printed"> Gift Hub</a>
                                        </li>

                                        <li className="mr-4 hover:underline md:mr-6 ">
                                            <a href="/product/Home%20Decor%20&%20Temple">Home and temple Decor</a>
                                        </li>
                                        <li className="mr-4 hover:underline md:mr-6 ">
                                            <a href="/about">
                                                About
                                            </a>
                                        </li>
                                        <li className="mr-4 hover:underline md:mr-6">
                                            <a href="/privacy">
                                                Privacy
                                            </a>
                                        </li>
                                        <li className="mr-4 hover:underline md:mr-6 " onClick={() => navigate("/contact")}>
                                            Contact
                                        </li>
                                    </ul>
                                    {/* </a> */}
                                    {/* <span className="h-6 w-px bg-gray-200" aria-hidden="true" /> */}
                                </div>

                                <div className="hidden lg:ml-4 mr-1 lg:flex">
                                    <a href="#" className="flex items-center text-gray-700 hover:text-gray-800">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png"
                                            alt="Solid T-shirt,Plain T-Shirt,Buy Plain Solid T-shirts,printo mug, Bewakoofy items, home decor items, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags"
                                            className="block h-auto w-5 flex-shrink-0"
                                            title="bharat india"
                                        />
                                        <span className="box-decoration-slice bg-gradient-to-r from-orange-600 to-green-500 ml-3 block text-white text-sm font-medium">Bharat</span>
                                        <span className="sr-only">change currency</span>
                                    </a>
                                </div>

                                {/* Search */}
                                {/* <div className=" lg:ml-6"> */}
                                <div className="flex items-center min-h-0l float-right">
                                    <label for="simple-search" className="sr-only">Search</label>
                                    <div className="relative w-full">
                                        <input type="text"
                                            id="simple-search"
                                            value={searchinput}
                                            onChange={(e) => onSearchinput(e.target.value)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2 p-1  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search color,brand, item..." required />
                                    </div>
                                    <button onClick={() => {
                                        // setTimeout(() => {
                                        props.onfilter(searchinput)
                                        // }, 2000)
                                    }} className="p-1 ml-1 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                        <span className="sr-only">Search</span>
                                    </button>
                                </div>

                                {/* </div> */}

                                {/* Cart */}
                                <div className="ml-2 flow-root lg:ml-4" onClick={() => props && props.onshoppingRender()}>
                                    <a href="#" className="group -m-2 flex items-center p-2">
                                        <ShoppingBagIcon
                                            className="h-6 w-6 flex-shrink-0 text-gray group-hover:text-gray-500"
                                            aria-hidden="true"

                                        />
                                        <span className="ml-2 w-5 animate-pulse rounded-full border-1  text-xl font-semibold  text-red-700 group-hover:text-green-800">{props && props.selectedItem}</span>
                                        <span className="sr-only">items in cart, view bag</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </nav>
            </header>
        </div>
    )
}
