import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios'
import { Helmet } from 'react-helmet-async';
import GooglePayButton from "@google-pay/button-react";
import ReadyToPayChangeResponse from '@google-pay/button-react'
import Loadinglogo from './../assist/loadinglogo.png'


const Paymentgetway = () => {
    const navigate = useNavigate()
    const userstate = useLocation()
    const [stateofcountry, setstateofcountry] = useState("")
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    let session = JSON.parse(sessionStorage.getItem('PHONEOTPINFO'))
    // console.log("session", session)
    // console.log(userstate.jti.split("").splice(0,5).join("")+userstate.exp )
    // console.log( userstate && userstate.state && userstate.state.user && userstate.state.user.multiFactor && userstate.state.user.multiFactor.user && userstate.state.user.multiFactor.user.phoneNumber && userstate.state.user.multiFactor.user.phoneNumber.slice(3, 13))
    let ListOfOrdered = useSelector(state => state.allProducts.order)
    console.log("List,", ListOfOrdered)
    // let subtotal = ListOfOrdered && ListOfOrdered.reduce((acc, curr) => {
    //     return acc + curr.price
    // }, 0)
    let subtotal = ListOfOrdered && ListOfOrdered.reduce((acc, curr) => {
        let itemtotal = curr.price * (curr.count ? curr.count : 1)
        return acc += itemtotal
    }, 0)
    let pincode = watch('delivery_zip')
    useEffect(() => {

        // console.log('billing-zip', pincode)
        if (pincode) {
            setTimeout(() => {
                axios.get(`https://api.postalpincode.in/pincode/${parseInt(pincode)}`)
                    .then(r => setstateofcountry(r.data))
            }, 2000)

        }


    }, [pincode])

    // const baseRequest = {
    //     apiVersion: 2,
    //     apiVersionMinor: 0
    // };
    // const google = window.google;
    // /**
    //  * Card networks supported by your site and your gateway
    //  *
    //  * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
    //  * @todo confirm card networks supported by your site and gateway
    //  */
    // const allowedCardNetworks = ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"];

    // /**
    //  * Card authentication methods supported by your site and your gateway
    //  *
    //  * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
    //  * @todo confirm your processor supports Android device tokens for your
    //  * supported card networks
    //  */
    // const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

    // /**
    //  * Identify your gateway and your site's gateway merchant identifier
    //  *
    //  * The Google Pay API response will return an encrypted payment method capable
    //  * of being charged by a supported gateway after payer authorization
    //  *
    //  * @todo check with your gateway on the parameters to pass
    //  * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#gateway|PaymentMethodTokenizationSpecification}
    //  */
    // const tokenizationSpecification = {
    //     type: 'PAYMENT_GATEWAY',
    //     parameters: {
    //         'gateway': 'example',
    //         'gatewayMerchantId': 'exampleGatewayMerchantId'
    //     }
    // };

    // /**
    //  * Describe your site's support for the CARD payment method and its required
    //  * fields
    //  *
    //  * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
    //  */
    // const baseCardPaymentMethod = {
    //     type: 'CARD',
    //     parameters: {
    //         allowedAuthMethods: allowedCardAuthMethods,
    //         allowedCardNetworks: allowedCardNetworks
    //     }
    // };

    // /**
    //  * Describe your site's support for the CARD payment method including optional
    //  * fields
    //  *
    //  * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
    //  */
    // const cardPaymentMethod = Object.assign({},
    //     baseCardPaymentMethod, {
    //     tokenizationSpecification: tokenizationSpecification
    // }
    // );

    // /**
    //  * An initialized google.payments.api.PaymentsClient object or null if not yet set
    //  *
    //  * @see {@link getGooglePaymentsClient}
    //  */
    // let paymentsClient = null;

    // /**
    //  * Configure your site's support for payment methods supported by the Google Pay
    //  * API.
    //  *
    //  * Each member of allowedPaymentMethods should contain only the required fields,
    //  * allowing reuse of this base request when determining a viewer's ability
    //  * to pay and later requesting a supported payment method
    //  *
    //  * @returns {object} Google Pay API version, payment methods supported by the site
    //  */
    // function getGoogleIsReadyToPayRequest() {
    //     return Object.assign({},
    //         baseRequest, {
    //         allowedPaymentMethods: [baseCardPaymentMethod]
    //     }
    //     );
    // }

    // /**
    //  * Configure support for the Google Pay API
    //  *
    //  * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|PaymentDataRequest}
    //  * @returns {object} PaymentDataRequest fields
    //  */
    // function getGooglePaymentDataRequest() {
    //     const paymentDataRequest = Object.assign({}, baseRequest);
    //     paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
    //     paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    //     paymentDataRequest.merchantInfo = {
    //         // @todo a merchant ID is available for a production environment after approval by Google
    //         // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
    //         // merchantId: '01234567890123456789',
    //         merchantName: 'Example Merchant'
    //     };
    //     return paymentDataRequest;
    // }

    // /**
    //  * Return an active PaymentsClient or initialize
    //  *
    //  * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
    //  * @returns {google.payments.api.PaymentsClient} Google Pay API client
    //  */
    // function getGooglePaymentsClient() {
    //     if (paymentsClient === null) {
    //         paymentsClient = new google.payments.api.PaymentsClient({
    //             environment: 'TEST'
    //         });
    //     }
    //     return paymentsClient;
    // }

    // /**
    //  * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
    //  *
    //  * Display a Google Pay payment button after confirmation of the viewer's
    //  * ability to pay.
    //  */
    // function onGooglePayLoaded() {
    //     const paymentsClient = getGooglePaymentsClient();
    //     paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
    //         .then(function (response) {
    //             if (response.result) {
    //                 addGooglePayButton();
    //                 // @todo prefetch payment data to improve performance after confirming site functionality
    //                 // prefetchGooglePaymentData();
    //             }
    //         })
    //         .catch(function (err) {
    //             // show error in developer console for debugging
    //             console.error(err);
    //         });
    // }

    // /**
    //  * Add a Google Pay purchase button alongside an existing checkout button
    //  *
    //  * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#ButtonOptions|Button options}
    //  * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines|Google Pay brand guidelines}
    //  */
    // function addGooglePayButton() {
    //     const paymentsClient = getGooglePaymentsClient();
    //     const button =
    //         paymentsClient.createButton({
    //             onClick: onGooglePaymentButtonClicked
    //         });
    //     document.getElementById('container').appendChild(button);
    // }

    // /**
    //  * Provide Google Pay API with a payment amount, currency, and amount status
    //  *
    //  * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo|TransactionInfo}
    //  * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
    //  */
    // function getGoogleTransactionInfo() {
    //     return {
    //         countryCode: 'en',
    //         currencyCode: 'INR',
    //         totalPriceStatus: 'FINAL',
    //         // set to cart total
    //         totalPrice: '1.00'
    //     };
    // }

    // /**
    //  * Prefetch payment data to improve performance
    //  *
    //  * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
    //  */
    // function prefetchGooglePaymentData() {
    //     const paymentDataRequest = getGooglePaymentDataRequest();
    //     // transactionInfo must be set but does not affect cache
    //     paymentDataRequest.transactionInfo = {
    //         totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
    //         currencyCode: 'INR'
    //     };
    //     const paymentsClient = getGooglePaymentsClient();
    //     paymentsClient.prefetchPaymentData(paymentDataRequest);
    // }

    // /**
    //  * Show Google Pay payment sheet when Google Pay payment button is clicked
    //  */
    // function onGooglePaymentButtonClicked() {
    //     const paymentDataRequest = getGooglePaymentDataRequest();
    //     paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

    //     const paymentsClient = getGooglePaymentsClient();
    //     paymentsClient.loadPaymentData(paymentDataRequest)
    //         .then(function (paymentData) {
    //             // handle the response
    //             processPayment(paymentData);
    //         })
    //         .catch(function (err) {
    //             // show error in developer console for debugging
    //             console.error(err);
    //         });
    // }

    // /**
    //  * Process payment data returned by the Google Pay API
    //  *
    //  * @param {object} paymentData response from Google Pay API after user approves payment
    //  * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData|PaymentData object reference}
    //  */
    // function processPayment(paymentData) {
    //     // show returned data in developer console for debugging
    //     console.log(paymentData);
    //     // @todo pass payment token to your gateway to process payment
    //     // paymentToken = paymentData.paymentMethodData.tokenizationData.token;
    // }

    // useEffect(() => {
    //     const script = document.createElement("script");

    //     script.src = "https://use.typekit.net/foobar.js";
    //     script.async = true;
    //     script.onload = onGooglePayLoaded()

    //     document.body.appendChild(script);
    // }, [])



    // // Gogle upi payment method for quick responce
    // function readAmount() {
    //     // return parseFloat(document.getElementById('amount').value);
    //     return 1;
    // }

    // /** Launches payment request flow when user taps on buy button. */
    // function onBuyClicked() {
    //     if (!window.PaymentRequest) {
    //         console.log('Web payments are not supported in this browser.');
    //         return;
    //     }

    //     const supportedInstruments = [{
    //         supportedMethods: ['https://tez.google.com/pay'],
    //         data: {
    //             pa: 'kumarkhadke01@oksbi',
    //             pn: 'KHADKE KUNAL MUKUND',
    //             tn: 'goshop Pay',
    //             mc: '5308690872',
    //             tr: (Math.random().toString(10).slice(2)).toString() + 'UPI',
    //             tid: (Math.random().toString(10).slice(2)).toString(),
    //             url: 'https://www.triokzz.com',
    //         },
    //     },
    //     {
    //         supportedMethods: ['https://pwp-server.appspot.com/pay-dev'],
    //         data: {
    //             pa: 'kumarkhadke01@oksbi',
    //             pn: 'KHADKE KUNAL MUKUND ',
    //             tn: 'goshop Pay',
    //             mc: '5308690872',
    //             tr: (Math.random().toString(10).slice(2)).toString() + 'UPI',
    //             tid: (Math.random().toString(10).slice(2)).toString(),
    //             url: 'http://localhost:3000',
    //         },
    //     }];

    //     const details = {
    //         total: {
    //             label: 'Total',
    //             amount: {
    //                 currency: 'INR',
    //                 value: new Number(String(readAmount(20))),
    //             },
    //         },
    //         displayItems: [{
    //             label: 'Original Amount',
    //             amount: {
    //                 currency: 'INR',
    //                 value: new Number(String(readAmount(20))),
    //             },
    //         }],
    //     };

    //     const options = {
    //         requestShipping: false,
    //         requestPayerName: false,
    //         requestPayerPhone: false,
    //         requestPayerEmail: false,
    //         shippingType: 'shipping',
    //     };

    //     let request = null;
    //     try {
    //         request = new PaymentRequest(supportedInstruments, details, options);
    //     } catch (e) {
    //         console.log('Payment Request Error: ' + e.message);
    //         return;
    //     }
    //     if (!request) {
    //         console.log('Web payments are not supported in this browser.');
    //         return;
    //     }

    //     var canMakePaymentPromise = checkCanMakePayment(request);
    //     canMakePaymentPromise
    //         .then((result) => {
    //             showPaymentUI(request, result);
    //         })
    //         .catch((err) => {
    //             console.log('Error calling checkCanMakePayment: ' + err);
    //         });
    // }

    // function checkCanMakePayment(request) {
    //     // Checks canMakePayment cache, and use the cache result if it exists.
    //     if (sessionStorage.hasOwnProperty(canMakePaymentCache)) {
    //         return Promise.resolve(JSON.parse(sessionStorage[canMakePaymentCache]));
    //     }

    //     // If canMakePayment() isn't available, default to assuming that the method is
    //     // supported.
    //     var canMakePaymentPromise = Promise.resolve(true);

    //     // Feature detect canMakePayment().
    //     if (request.canMakePayment) {
    //         canMakePaymentPromise = request.canMakePayment();
    //     }

    //     return canMakePaymentPromise
    //         .then((result) => {
    //             // Store the result in cache for future usage.
    //             sessionStorage[canMakePaymentCache] = result;
    //             return result;
    //         })
    //         .catch((err) => {
    //             console.log('Error calling canMakePayment: ' + err);
    //         });
    // }
    // /**
    //  * Show the payment request UI.
    //  *
    //  * @private
    //  * @param {PaymentRequest} request The payment request object.
    //  * @param {Promise} canMakePayment The promise for whether can make payment.
    //  */
    // function showPaymentUI(request, canMakePayment) {
    //     // Redirect to play store if can't make payment.
    //     if (!canMakePayment) {
    //         redirectToPlayStore();
    //         return;
    //     }

    //     if (!request) {
    //         return <img src={Loadinglogo} alt="loading" title="triokzz" class="w-auto" />
    //     }

    //     // Set payment timeout.
    //     let paymentTimeout = window.setTimeout(function () {
    //         window.clearTimeout(paymentTimeout);
    //         request.abort()
    //             .then(function () {
    //                 console.log('Payment timed out after 20 minutes.');
    //             })
    //             .catch(function () {
    //                 console.log('Unable to abort, user is in the process of paying.');
    //             });
    //     }, 20 * 60 * 1000); /* 20 minutes */

    //     request.show()
    //         .then(function (instrument) {
    //             window.clearTimeout(paymentTimeout);
    //             processResponse(instrument);  // Handle response from browser.
    //         })
    //         .catch(function (err) {
    //             console.log(err);
    //         });
    // }

    // /** Handle Google Pay not ready to pay case. */
    // function handleNotReadyToPay() {
    //     alert('Google Pay is not ready to pay.');
    // }
    // function redirectToPlayStore() {
    //     if (alert('Google Pay is not ready to pay.')) {
    //         window.location.href =
    //             'https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user.alpha'
    //     };
    // }

    // /* Converts the payment response into a JSON string.
    //  *
    //  * @private
    //  * @param {PaymentResponse} paymentResponse The payment response to convert.
    //  * @return {string} The string representation of the payment response.
    //  */
    // function paymentResponseToJsonString(paymentResponse) {
    //     // PaymentResponse is an interface, JSON.stringify works only on dictionaries.
    //     var paymentResponseDictionary = {
    //         methodName: paymentResponse.methodName,
    //         details: paymentResponse.details,
    //         shippingAddress: JSON.stringify(paymentResponse.shippingAddress),
    //         shippingOption: paymentResponse.shippingOption,
    //         payerName: paymentResponse.payerName,
    //         payerPhone: paymentResponse.payerPhone,
    //         payerEmail: paymentResponse.payerEmail,
    //     };
    //     return JSON.stringify(paymentResponseDictionary, undefined, 2);
    // }

    // /**
    //  * Process the response from browser.
    //  *
    //  * @private
    //  * @param {PaymentResponse} instrument The payment instrument that was authed.
    //  */
    // function processResponse(instrument) {
    //     var instrumentString = JSON.stringify(instrument);
    //     console.log(instrumentString);

    //     fetch('/buy', {
    //         method: 'POST',
    //         headers: new Headers({
    //             'Content-Type': 'application/json'
    //         }),
    //         body: instrumentString,
    //     })
    //         .then(function (buyResult) {
    //             if (buyResult.ok) {
    //                 return buyResult.json();
    //             }
    //             console.log('Error sending instrument to server.');
    //         })
    //         .then(function (buyResultJson) {
    //             completePayment(instrument, buyResultJson.status, buyResultJson.message);

    //         })
    //         .catch(function (err) {
    //             console.log('Unable to process payment. ' + err);
    //         });
    // }

    // /**
    //  * Notify browser that the instrument authorization has completed.
    //  *
    //  * @private
    //  * @param {PaymentResponse} instrument The payment instrument that was authed.
    //  * @param {string} result Whether the auth was successful. Should be either
    //  * 'success' or 'fail'.
    //  * @param {string} msg The message to log in console.
    //  */
    // function completePayment(instrument, result, msg) {
    //     instrument.complete(result)
    //         .then(function () {
    //             console.log('Payment completes.');
    //             console.log(msg);
    //             document.getElementById('inputSection').style.display = 'none'
    //             document.getElementById('outputSection').style.display = 'block'
    //             document.getElementById('response').innerHTML =
    //                 JSON.stringify(instrument, undefined, 2);
    //         })
    //         .catch(function (err) {
    //             console.log(err);
    //         });
    // }

    // // Global key for canMakepayment cache.
    // const canMakePaymentCache = 'canMakePaymentCache';

    // /**
    //  * Check whether can make payment with Google Pay or not. It will check session storage
    //  * cache first and use the cache directly if it exists. Otherwise, it will call
    //  * canMakePayment method from PaymentRequest object and return the result, the
    //  * result will also be stored in the session storage cache for future usage.
    //  *
    //  * @private
    //  * @param {PaymentRequest} request The payment request object.
    //  * @return {Promise} a promise containing the result of whether can make payment.
    //  */
    // function checkCanMakePayment(request) {
    //     // Check canMakePayment cache, use cache result directly if it exists.
    //     if (sessionStorage.hasOwnProperty(canMakePaymentCache)) {
    //         return Promise.resolve(JSON.parse(sessionStorage[canMakePaymentCache]));
    //     }

    //     // If canMakePayment() isn't available, default to assume the method is
    //     // supported.
    //     var canMakePaymentPromise = Promise.resolve(true);

    //     // Feature detect canMakePayment().
    //     if (request.canMakePayment) {
    //         canMakePaymentPromise = request.canMakePayment();
    //     }

    //     return canMakePaymentPromise
    //         .then((result) => {
    //             // Store the result in cache for future usage.
    //             sessionStorage[canMakePaymentCache] = result;
    //             return result;
    //         })
    //         .catch((err) => {
    //             console.log('Error calling canMakePayment: ' + err);
    //         });
    // }
    const onSubmit = (data, e) => {
        // console.log(data, e)
        // const withpaymentsuccessful = {
        //     "product_id": 1,
        //     "product_for": userstate.state.name,
        //     "email": userstate.state.email,
        //     "picture": userstate.state.picture,
        //     "location": userstate.state.locale,
        //     "section": ListOfOrdered,
        //     "date": new Date(),
        //     "approvedby_admin": "Not Approved",
        //     "delivered": false,
        //     "received": false,
        //     "total": subtotal
        // }
        // onBuyClicked()
        // console.log("withpaymentsuccessful", withpaymentsuccessful)
        // navigate("/about", {
        //     data: data
        // })
        // console.log("data", data)

        var form = document.createElement("form");
        form.target = "_self";
        form.method = "POST";
        form.action = "https://api.triokzz.com/ccavRequestHandler";
        var params = {
            "merchant_id": data.merchant_id,
            "amount": data.amount,
            "billing_address": data.billing_address,
            "billing_email": data.billing_email,
            "billing_name": data.billing_name,
            "delivery_name": data.delivery_name,
            "currency": data.currency,
            "delivery_country": "India",
            "delivery_address": data.delivery_address,
            "delivery_city": data.delivery_city,
            "billing_city": data.delivery_city,
            "delivery_state": data.delivery_state,
            "billing_state": data.delivery_state,
            "billing_country": "India",
            "billing_tel": data.delivery_tel,
            "delivery_tel": data.delivery_tel,
            "delivery_zip": data.delivery_zip,
            "billing_zip": data.delivery_zip,
            "language": data.language,
            "order_id": data.order_id,
            "redirect_url": data.redirect_url,
            "cancel_url": data.cancel_url
        };

        // var params = encodeURI(data)

        for (var i in params) {
            if (params.hasOwnProperty(i)) {
                var input = document.createElement('input');
                input.type = 'hidden';
                input.name = i;
                input.value = params[i];
                form.appendChild(input);
            }
        }

        document.body.appendChild(form);
        form.submit();

        setTimeout(() => {
            window.open('', '_self');
        }, 200);
        // window.open('', 'view');
        // var url = 'https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/ccavRequestHandler'
        // var datas = JSON.stringify(data)

        const controller = new AbortController()

        // 5 m timeout:

        // const timeoutId = setTimeout(() => controller.abort(), 10000)
        // setTimeout(() => {
        axios.post("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/products", {
            "product_id": 1,
            "order_id": data.order_id,
            "section": ListOfOrdered,
            "email": data.billing_email,
            "phone": data.delivery_tel,
            "delivery_name": data.delivery_name,
            "billing_address": data.delivery_address,
            "street_address": data.billing_address,
            "state": data.delivery_state,
            "city": data.delivery_city,
            "pin_code": data.delivery_zip,
            // "payment_success": result.order_status,
            "payment_success": "Inprogress",
            "payment_location": "en",
            "approvedby_admin": "Approved",
            "delivered": false,
            "received": false,
            // "date": result.trans_date,
            // "date": "19/12/2023 01:49:04",
            "total": data.amount,
            "product_for": data.billing_name,
            // "tracking_id": result.tracking_id
            // "tracking_id": "152865758552"
        }).then(r => r).catch(e => e)
        // }, ((5 * 60) * 1000))


        // setTimeout(async () => {
        //     await axios.get('https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/ccavResponseHandler').then(r => {
        //         console.log(r)
        //         var toj = r.data
        //         var result = JSON.parse('{"' + toj.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
        //         console.log(result)
        //         if (result.order_status == "Success") {
        //             axios.post("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/products", {

        //                 "order_id": data.order_id,
        //                 "section": [{
        //                     "brand": "jigari",
        //                     "color": "sky pink",
        //                     "offer_prize": 700,
        //                     "product_detail": "slim fit",
        //                     "quantity": 1,
        //                     "real_prize": 1250,
        //                     "size": 32,
        //                     "type": "plain",
        //                     "url": "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
        //                     "id": 0,
        //                     "product_name": "elastic jogger"
        //                 }, {
        //                     "brand": "jigari",
        //                     "color": "sky pink",
        //                     "offer_prize": 400,
        //                     "product_detail": "slim fit neck tees",
        //                     "quantity": 2,
        //                     "real_prize": 800,
        //                     "size": 32,
        //                     "type": "plain",
        //                     "url": "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
        //                     "id": 0,
        //                     "product_name": "elastic jogger"
        //                 }],
        //                 "email": data.billing_email,
        //                 "phone": data.delivery_tel,
        //                 "billing_address": data.delivery_address,
        //                 "street_address": data.billing_address,
        //                 "state": data.delivery_state,
        //                 "city": data.delivery_city,
        //                 "pin_code": data.delivery_zip,
        //                 "payment_success": result.order_status,
        //                 "payment_location": "en",
        //                 "approvedby_admin": "Approved",
        //                 "delivered": false,
        //                 "received": false,
        //                 "date": result.trans_date,
        //                 "total": data.amount,
        //                 "product_for": data.billing_name,
        //                 "tracking_id": result.tracking_id
        //             })
        //         } else {
        //             axios.post("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/products", {

        //                 "order_id": data.order_id,
        //                 "section": [{
        //                     "brand": "jigari",
        //                     "color": "sky pink",
        //                     "offer_prize": 700,
        //                     "product_detail": "slim fit",
        //                     "quantity": 1,
        //                     "real_prize": 1250,
        //                     "size": 32,
        //                     "type": "plain",
        //                     "url": "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
        //                     "id": 0,
        //                     "product_name": "elastic jogger"
        //                 }, {
        //                     "brand": "jigari",
        //                     "color": "sky pink",
        //                     "offer_prize": 400,
        //                     "product_detail": "slim fit neck tees",
        //                     "quantity": 2,
        //                     "real_prize": 800,
        //                     "size": 32,
        //                     "type": "plain",
        //                     "url": "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
        //                     "id": 0,
        //                     "product_name": "elastic jogger"
        //                 }],
        //                 "email": data.billing_email,
        //                 "phone": data.delivery_tel,
        //                 "billing_address": data.delivery_address,
        //                 "street_address": data.billing_address,
        //                 "state": data.delivery_state,
        //                 "city": data.delivery_city,
        //                 "pin_code": data.delivery_zip,
        //                 "payment_success": result.order_status,
        //                 "payment_location": "en",
        //                 "approvedby_admin": "Approved",
        //                 "delivered": false,
        //                 "received": false,
        //                 "date": result.trans_date,
        //                 "total": data.amount,
        //                 "product_for": data.billing_name,
        //                 "tracking_id": result.tracking_id
        //             })
        //         }

        //     }).catch((err) => {
        //         console.log("error:", err)
        //         // if (err.response.status) {
        //         window.open("https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction", '_blank')
        //         // }
        //     })
        // }, ((2 * 60) * 1000))

        // http.post(url, datas).subscribe((response) => {
        //     window.open(`${url}?datas=${encodeURI(datas)}`, '_blank')
        // });
    }
    return (<div>
        <Helmet>
            {/* <!-- Google tag (gtag.js) --> */}
            <script async="" src="https://www.googletagmanager.com/gtag/js?id=G-RWZEXW6VMP"></script>
            <script>
                {
                    `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "G-RWZEXW6VMP");`
                }
            </script>

            {/* <!-- Google tag (gtag.js) --> */}
            <script async="" src="https://www.googletagmanager.com/gtag/js?id=AW-11415759693"></script>
            <script>
                {
                    `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "AW-11415759693");`
                }
            </script>

            {/* <!-- Event snippet for Add to basket (1) conversion page --> */}
            <script>
                {
                    `gtag("event", "conversion", {
                                send_to: "AW-11415759693/5sa9CJmbsZEZEM3WusMq",
                                });
                                `
                }
            </script>
            <script>
                {
                    `gtag("event", "conversion", {
                                send_to: "AW-11415759693/haFsCPXm5_cYEM3WusMq",
                                });
                                `
                }
            </script>
            {/* <!-- Event snippet for Begin checkout conversion page --> */}
            {/* <script>
                            {
                                `gtag("event", "conversion", {
                                send_to: "AW-11415759693/QtgvCJ6asZEZEM3WusMq",
                                });`
                            }
                        </script> */}
            {/* <!-- Event snippet for Purchase conversion page --> */}
            <script>
                {`gtag("event", "conversion", {
                                send_to: "AW-11415759693/XSgmCJiasZEZEM3WusMq",
                            transaction_id: "",
                                });
                            `}
            </script>
            <script>
                {
                    `!(function (f, b, e, v, n, t, s) {
                           if (f.fbq) return;
                            n = f.fbq = function () {
                                n.callMethod
                                    ? n.callMethod.apply(n, arguments)
                                    : n.queue.push(arguments);
                           };
                            if (!f._fbq) f._fbq = n;
                            n.push = n;
                            n.loaded = !0;
                            n.version = "2.0";
                            n.queue = [];
                            t = b.createElement(e);
                            t.async = !0;
                            t.src = v;
                            s = b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t, s);
                            })(
                            window,
                            document,
                            "script",
                            "https://connect.facebook.net/en_US/fbevents.js"
                            );
                            fbq("init", "232698863167434");
                            fbq("track", "PageView");
                            fbq("track", "AddToCart");
                        `}
            </script>
            <noscript
            >
                {`
                                <img
                                height="1"
                                width="1"
                                style="display: none"
                                src="https://www.facebook.com/tr?id=232698863167434&ev=PageView&noscript=1"
                            />
                         `}
            </noscript>
        </Helmet >
        <form
            onSubmit={handleSubmit(onSubmit)}
        // method="POST" name="customerData" action="https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/ccavRequestHandler"
        >
            {/* <div class="h-screen w-full flex flex-col items-center justify-center bg-teal-lightest font-sans">
                <div v-if="modal.visible" class="h-screen w-full absolute flex items-center justify-center bg-modal">
                    <div class="bg-white rounded shadow p-8 m-4 max-w-xs max-h-full text-center overflow-y-scroll">
                        <div class="mb-4">
                            <h1>Welcome!</h1>
                        </div>
                        <div class="mb-8">
                            <p>Triokzz still in testing mode.We sorry for inconvenience but yes, triokzz will be best option for you soon..</p>
                        </div>
                        <div class="flex justify-center">
                            <button class="flex-no-shrink text-white py-2 px-4 rounded bg-teal hover:bg-teal-dark">Thank you</button>
                        </div>
                    </div>
                </div>
            </div> */}


            <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
                <p className="text-xl font-medium">Payment Details</p>
                <p className="text-gray-400">Complete your order by providing your correct payment details.</p>
                <div className="">
                    <label for="billing_name" className="mt-4 mb-2 block text-sm font-medium">Card Holder</label>
                    <div className="relative">
                        <input type="text" id="billing_name" {...register("billing_name", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="Full name of card holder" />
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                            </svg>
                        </div>
                    </div>
                    <label for="billing_email" className="mt-4 mb-2 block text-sm font-medium">Email</label>
                    <div className="relative">
                        <input type="billing_email" id="billing_email" {...register("billing_email", { required: true })} value={"" || userstate && userstate.state && userstate.state.email} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="email@gmail.com" />
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                            </svg>
                        </div>
                    </div>
                    <div className="relative">
                        <input type="text" id="delivery_name" {...register("delivery_name", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="Receiver name" />
                        {errors.delivery_name && <span style={{ color: "red" }}>please enter receiver name</span>}
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                            </svg>
                        </div>

                    </div>

                    {/* <label for="card-no" className="mt-4 mb-2 block text-sm font-medium">Card Details</label>
                    <div className="flex">
                        <div className="relative w-7/12 flex-shrink-0">
                            <input type="text" id="card-no" {...register("card-no")} className="w-full rounded-md border border-gray-200 px-2 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="xxxx-xxxx-xxxx-xxxx" />
                            <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                                <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                                    <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                                </svg>
                            </div>
                        </div>
                        <input type="text" {...register("credit-expiry")} className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="MM/YY" />
                        <input type="number" {...register("credit-cvc")} className="w-1/6 flex-shrink-0 rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="CVC" />
                    </div> */}
                    <label for="delivery_address" className="mt-4 mb-2 block text-sm font-medium">Billing Address</label>
                    <div className="flex grid grid-cols-1 lg:grid-cols-2  sm:flex-row">
                        <input type="text" {...register("delivery_zip", { required: true })} className="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-pink-500" placeholder="pincode" />


                    </div>
                    <div className="relative">
                        <select type="text" {...register("delivery_state", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500
                         invalid:border-pink-500 invalid:text-pink-600
                         focus:invalid:border-pink-500 focus:invalid:ring-pink-500">
                            {stateofcountry && stateofcountry[0].PostOffice && stateofcountry[0].PostOffice.map((stateof, i) => <option value={stateof.Circle}>{stateof.Circle}</option>)}
                        </select>
                        <select type="text" {...register("delivery_city", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500
                         invalid:border-pink-500 invalid:text-pink-600
                         focus:invalid:border-pink-500 focus:invalid:ring-pink-500">
                            {stateofcountry && stateofcountry[0].PostOffice && stateofcountry[0].PostOffice.map((stateof, i) => <option value={stateof.Name}>{stateof.Name}</option>)}
                        </select>
                    </div>
                    {/* <div className="relative">
                        <input type="text" {...register("delivery_state", { required: true })} className="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-pink-500" placeholder="delivery state" />
                        <input type="text" {...register("delivery_city", { required: true })} className="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-pink-500" placeholder="delivery city" />

                    </div> */}
                    <div className="relative">
                        <input type="text" id="delivery_address" {...register("delivery_address", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="flat no./house/socity/delivery Address" />
                        {errors.delivery_address && <span style={{ color: "red" }}>delivery address is required</span>}
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <img className="h-4 w-4 object-contain" src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png" alt="" />
                        </div>
                    </div>
                    <div className="relative">
                        <input type="text" id="billing-address" {...register("billing_address", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="Street Address/near by location" />
                        {errors.billing_address && <span style={{ color: "red" }}>billing address is required</span>}
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <img className="h-4 w-4 object-contain" src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png" alt="" />
                        </div>
                    </div>
                    <div className=" grid grid-cols-1 lg:grid-cols-1  sm:flex-row">
                        <input type="text" name="delivery_tel" value={"" || session && session.phoneNumber && session.phoneNumber.slice(3, 13)} {...register("delivery_tel", { required: true, maxLength: 10, pattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/ })}
                            className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500  invalid:border-pink-500 invalid:text-pink-600
                     focus:invalid:border-pink-500 focus:invalid:ring-pink-500" placeholder="Mobile Number" />
                        {errors.delivery_tel && <span style={{ color: "red" }}>please enter your 10 digit mobile number</span>}
                    </div>
                    <label for="delivery_address" className="mt-4 mb-2 block text-sm font-medium">Suggestion</label>
                    <div className="flex grid grid-cols-1 lg:grid-cols-1  sm:flex-row">

                        <input type="text" {...register("merchant_param1")} placeholder="To improve our quality of service ..." className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" />
                    </div>
                    {/* total payment  */}


                    <div className="mt-6 border-t border-b py-2">
                        <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-gray-900">Subtotal</p>
                            <p className="font-semibold text-gray-900">{subtotal}.00 Rs</p>
                        </div>
                        {subtotal < 1449 && <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-gray-900">Shipping</p>
                            <p className="font-semibold text-gray-900">{ListOfOrdered && ListOfOrdered[0].delivery_charge}.00 Rs</p>
                        </div>}
                    </div>
                    <div className="mt-6 flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-900">Total</p>
                        <p className="text-2xl font-semibold text-gray-900">₹ {subtotal < 1449 ? (subtotal + (ListOfOrdered && ListOfOrdered[0].delivery_charge)) && (subtotal + (ListOfOrdered && ListOfOrdered[0].delivery_charge)).toString() : subtotal && subtotal.toString()} /-</p>
                    </div>

                    <table width="40%" height="100" border='1' align="right">
                        <tr>
                            {/* <td>Redirect URL</td> */}
                            <td><input type="hidden" name="redirect_url" {...register("redirect_url", { required: true })} value="https://api.triokzz.com/ccavResponseHandler" />
                            </td>
                        </tr>
                        <tr>
                            {/* <td>Cancel URL</td> */}
                            <td><input type="hidden" name="cancel_url" {...register("cancel_url", { required: true })} value="https://api.triokzz.com/ccavResponseHandler" />
                            </td>
                        </tr>
                        <tr disabled>
                            {/* <td>Merchant Id</td> */}
                            <td><input type="hidden"  {...register("merchant_id", { required: true })} id="merchant_id" value="2976964" /> </td>
                        </tr>
                        <tr>
                            {/* <td>Order Id</td> */}
                            {/* <td><input type="hidden" {...register("order_id", { required: true })} value={((Math.random().toString(10).slice(2)).toString()) || (userstate && userstate.jti.split("").splice(0, 5).join("") + userstate & userstate.exp)} /></td> */}
                            <td><input type="hidden" {...register("order_id", { required: true })} value={(userstate && userstate.state && userstate.state.jti && userstate.state.jti.split("").splice(0, 5).join("") + userstate && userstate.state && userstate.state.exp) || (session && session.apiKey && session.apiKey.split("").splice(0, 5).join("") + subtotal && subtotal.toString())} /></td>

                        </tr>
                        <tr>
                            <td>Currency</td>
                            <td><input type="text" name="currency" {...register("currency", { required: true })} value="INR" /></td>
                        </tr>
                        <tr>
                            <td>Amount(₹)</td>
                            <td><input type="text" {...register("amount", { required: true })} value={subtotal < 1449 ? (subtotal + (ListOfOrdered && ListOfOrdered[0].delivery_charge)) && (subtotal + (ListOfOrdered && ListOfOrdered[0].delivery_charge)).toString() : subtotal && subtotal.toString()} readOnly /></td>
                            {/* <td><input type="text" name="amount"  {...register("amount", { required: true })} value="1.00" /></td> */}
                        </tr>
                        <tr>
                            <td>Language</td>
                            <td><input type="text" {...register("language", { required: true })} id="language" value="EN" /></td>
                        </tr>

                    </table>
                    <input type="submit" className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white" value="Checkout" />
                </div>
                <div>
                </div>
            </div>
        </form>
        {/* <h4>or</h4>
        <div id="google_pay_iframe">
            <div id="container"></div>
        </div>


        <button onClick={() => onBuyClicked()} class="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white">Buy Via Upi</button> */}
    </div>
    )
}

export default Paymentgetway