import {
    useParams, useNavigate
} from "react-router-dom";
import Logo from './../assist/triokzzlohgo.svg';
import { Helmet } from 'react-helmet-async';

const Aboutus = () => {
    const navigate = useNavigate()
    return (
        <div class="bg-red-50">
            <nav class="flex items-center justify-between flex-wrap bg-teal-500 p-6">
                <div class="flex items-center flex-shrink-0 text-white mr-6" onClick={() => navigate(`/product/all`)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                    <span class="font-semibold text-xl tracking-tight">Triokzz</span>

                </div>
            </nav>
            <Helmet>
                <title>About- Triokzz is a software development,ecommerce site, selling partner and product base company.Focussing on designs, develops, and maintains software applications, frameworks, and tools</title>
                <meta name="description"
                    content="Triokzz is a software development,selling partner and product base company designs, develops, and maintains software applications, frameworks, and tools">
                </meta>
                <meta name="author" content="Triokzz"></meta>
                <link rel="canonical" href="https://www.triokzz.com/about" />
                <meta name="keywords" content="ecommerce site development, About triokx, About triokze, Home decor, Handicraft items,pink world of mens womens faith meets fashion,trends"></meta>
                <meta name="robots" content="index, follow" />
                <meta name="robots" content="max-image-preview:large" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Triokzz -About us" />
                <meta property="og:description" content="Triokzz is a software development ,selling partner and product base company-designs, develops, and maintains software applications, frameworks, and tools" />
                <meta property="og:image" content="https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JI-T-13-17.png" />
                <meta property="og:video" content="https://www.youtube.com/watch?v=A51nvgt99Cc" />
                <meta property="og:url" content="https://www.triokzz.com/about" />
                <meta property="og:site_name" content="Triokzz is a software development,selling partner and product base company- designs, develops, and maintains software applications, frameworks, and tools" />
            </Helmet>
            <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
                <iframe class="absolute aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20 inset-0 -z-10 h-full w-full object-cover object-right" width="500" height="180" autoplay muted loop src="https://shopnowindia.s3.ap-south-1.amazonaws.com/advertising+video/Matrix+Promotion+(1).mp4" type="video/mp4" >
                </iframe>

                <div
                    className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div
                    className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>

                <video>
                    <source
                        src="https://www.youtube.com/watch?v=A51nvgt99Cc"
                        alt="About Triokzz Online"
                        className="relative inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                    />
                </video>

                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">About</h1>
                        <p className="mt-6 text-lg leading-8 text-gray-300 capitalize">
                            Triokzz is a software development ,selling partner and product base company.
                            Triokzz provides a virtual marketplace where customers can browse, select items, add it to bag and purchase items, often facilitating secure transactions and convenient payment methods. Triokzz have transformed traditional retail, offering a global reach, 24/7 accessibility, and personalized shopping experiences for users.
                            We are profounder.we are a selling partner.Our marketing team roam around the world and get best products for you to decore your home,kitchen , merchandies the cloth .
                            We collect the order from you and deliver best product to your door step.</p>
                        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl capitalize">what information we collect</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300 capitalize">
                            customer full name , mobile and email address, billing and shipping address
                            ,other information relevant to customer surveys or efforts.
                        </p>
                        <ul class='list-inside mt-6 text-lg leading-8 text-gray-300 capitalize'><li>we require the information to understand the needs and provide you with better service</li>
                            <li>we may periodically send promotional emails ,sms about new products, special offers or other information which we think you may find better in that.</li></ul>
                    </div>
                </div>
            </div>
            <div class="max-w-screen-xl mx-auto py-8 px-4 lg:py-16 lg:px-6">
                <div class="text-center mb-10">
                    <h2 class="text-4xl tracking-tight font-bold text-primary-800"> Features</h2>
                </div>

                <div class="flex flex-col md:flex-row">

                    <div class="flex-1 flex flex-col sm:flex-row flex-wrap -mb-4 -mx-2">
                        <div class="w-full sm:w-1/2 mb-4 px-2 ">
                            <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                                <h3 class="text-2xl font-bold text-md mb-6">Dynamic Personalization:</h3>
                                <p class="text-sm">Our platform leverages user data and behavior to provide a highly personalized experience, with dynamic content and product recommendations that change in real-time.</p>
                            </div>
                        </div>
                        <div class="w-full sm:w-1/2 mb-4 px-2 ">
                            <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                                <h3 class="text-2xl font-bold text-md mb-6">Mobile-Optimized Interface</h3>
                                <p class="text-sm"> Our website is designed with a mobile-first approach, offering a seamless browsing experience across all devices, including smartphones and tablets.</p>
                            </div>
                        </div>

                        <div class="w-full sm:w-1/2 mb-4 px-2 ">
                            <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                                <h3 class="text-2xl font-bold text-md mb-6">24/7 Customer Support</h3>
                                <p class="text-sm">ur IND.-based customer support team is available around the clock to answer any questions, resolve any issues, and provide helpful solutions. Whether it's through email, phone, or live chat, we're always here to support you.</p>
                            </div>
                        </div>

                        <div class="w-full sm:w-1/2 mb-4 px-2 ">
                            <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                                <h3 class="text-2xl font-bold text-md mb-6">Secure Payment Processing</h3>
                                <p class="text-sm">We use cutting-edge security measures to protect our customers' sensitive information and ensure the safety of all transactions made on our site.</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <footer class=" relative 
             inset-x-0 bottom-0 bg-gray-900 rounded-lg shadow dark:bg-gray-900 m-2">
                <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <a href="#" class="flex items-center mb-4 sm:mb-0">
                            <img src={Logo} class="h-12 mr-3" alt="shoppp Logo" />
                        </a>
                        <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white">
                            <li class="mr-4 hover:underline md:mr-6 " onClick={() => navigate("/product/mens")}>
                                Home
                            </li>
                            <li class="mr-4 hover:underline md:mr-6 " onClick={() => navigate("/about")}>
                                About Us
                            </li>
                            <li class="mr-4 hover:underline md:mr-6" onClick={() => navigate("/privacy")}>
                                Privacy
                            </li>
                            <li class="mr-4 hover:underline md:mr-6 ">
                                <a href="/contact" class="hover:underline">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <span class="block text-sm text-white sm:text-center dark:text-gray-400">© 2023 Developed By <a href="/product/privacy" class="hover:underline">Triokzz</a>. All Rights Reserved.</span>
                </div>
            </footer>
        </div>
    )
}
export default Aboutus