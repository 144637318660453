import { Fragment, useState, useEffect } from 'react'
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import axios from 'axios';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/20/solid';
import { AddtocardList } from './../Redux/action';
import { useDispatch } from 'react-redux';
import {
    useParams, useNavigate
} from "react-router-dom";
import Sizeguard from './../assist/sizeguard.png'
import { Helmet } from 'react-helmet-async';
import PrintedMugDetail from "./../assist/PrintedMugDetail.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const product = {
    name: 'Basic Tee 6-Pack ',
    price: '$192',
    rating: 5,
    reviewCount: 267,
    href: 'https://g.co/kgs/qkQTno8',
    // imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-quick-preview-02-detail.jpg',
    imageAlt: 'Two each of gray, white, and black shirts arranged on table.',
    colors: [
        { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
        { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
        { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
    ],
    sizes: [
        { name: 'XXS', inStock: true },
        { name: 'XS', inStock: true },
        { name: 'S', inStock: true },
        { name: 'M', inStock: true },
        { name: 'L', inStock: true },
        { name: 'XL', inStock: true },
        { name: 'XXL', inStock: true },
        { name: 'XXXL', inStock: false },
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ProductPage = (props) => {
    const { item, subproduct } = useParams()
    const [subNavItem, setSubNavItem] = useState(subproduct)
    const [navItem, setNavItem] = useState(item)
    const [serchableoutput, setserchableoutput] = useState([])
    const [open, setOpen] = useState(props.open)
    const [count, setarr] = useState(new Array(5).fill(null).map((_, i) => i))
    const [selectedColor, setSelectedColor] = useState("")
    const [selectedSize, setSelectedSize] = useState([])
    const [quantity, setQuantity] = useState(1)
    console.log(quantity)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const checkout = (items) => {
        dispatch(AddtocardList(items))
        navigate(`/login`)
    }

    const onfilter = async (subNavItem) => {
        // console.log("asasa")
        let searchableoutput = []
        let serching = subNavItem && subNavItem.toLowerCase()
        if (serching && serching.length > 2) {
            let TrendingProductsforsearch = await axios.get("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/products")
                .then(r => r)
                .then(res => res.data)
                .catch(err => console.log(err))


            let productaction = TrendingProductsforsearch && TrendingProductsforsearch.products && TrendingProductsforsearch.products.find((val, i, arr) => {
                return val.product_for == navItem
            })

            productaction.section.map((value, index) => {
                let [keys] = Object.keys(value)
                let arr = value[keys]
                let hello = arr.map(val => val)
                console.log(hello)
                const filteredval = hello.filter(info => {
                    if (info.color.toLowerCase().includes("royal blue")) {
                        setSelectedSize(info.Sizeguard[0])
                        setSelectedColor(info.color)
                        return info
                    }
                }
                )

                if (filteredval.length > 0) {
                    setserchableoutput(filteredval)
                }

                return filteredval
            })

        }

    }

    //onback popup close
    useEffect(() => {
        async function fetchData() {
            // console.log("asasa")
            let searchableoutput = []
            let serching = subNavItem && subNavItem.toLowerCase()
            if (serching && serching.length > 2) {
                let TrendingProductsforsearch = await axios.get("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/organic_products")
                    .then(r => r)
                    .then(res => res.data)
                    .catch(err => console.log(err))


                let productaction = TrendingProductsforsearch && TrendingProductsforsearch.products && TrendingProductsforsearch.products.find((val, i, arr) => {
                    return val.product_for == navItem
                })

                productaction && productaction.section && productaction.section.map((value, index) => {
                    let [keys] = Object.keys(value)
                    let arr = value[keys]
                    let hello = arr.map(val => val)

                    const filteredval = hello.filter(info => {
                        setSelectedSize(info.size[0])
                        setSelectedColor(info.color)

                        return serching.includes((info && info.id && info.id.toLowerCase()))

                    }
                    )
                    // console.log(filteredval)
                    if (filteredval.length) {
                        setserchableoutput(filteredval)
                    }

                    // return filteredval
                })

            }
        }
        fetchData();

    }, [])

    // console.log(val, selectedSize)
    return (
        <div className="fixed z-40 inset-0 z-10 overflow-y-auto">
            <nav class="flex items-center justify-between flex-wrap bg-teal-500 p-6">
                <div class="flex items-center flex-shrink-0 text-white mr-6" onClick={() => navigate(`/list/grocery/${navItem}`)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                    <span class="font-semibold text-xl tracking-tight">Triokzz</span>

                </div>
            </nav>
            {serchableoutput && serchableoutput.map((val, id) => {

                return (
                    <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">

                        <Helmet>
                            <title>{val.product_name}</title>
                            <meta name="description"
                                content={val.product_detail.substr(0, 150)}>
                            </meta>
                            <meta name="robots" content="index, follow" />
                            <meta name="robots" content="max-image-preview:large" />
                            <meta name="google-site-verification" content="vwUAwcHi4pY-Lgm6a9JJyOwn1GLbkA7gO6fvQOy4FvU" />
                            <meta name="keywords" content={`${navItem}, ${val.type}, ${val.color}, ${val.product_name}`}></meta>
                            <link rel="canonical" href={`https://www.triokzz.com/organic+grocery/${navItem}/${val.type}+${val.id}`} />
                            <meta property="og:type" content="website" />
                            <meta property="og:title" content={val.product_name + val.type} />
                            <meta property="og:description" content={val.product_name + val.product_detail.substr(0, 130)} />
                            <meta property="og:image" content={val.url} />
                            <meta property="og:image:height" content="1200" />
                            <meta property="og:image:width" content="630" />
                            <meta property="og:url" content={`https://www.triokzz.com/organic+grocery/${navItem}/${val.type}+${val.id}`} />
                            <meta property="og:image:alt" content={`"${val.product_name} Images T-shirt Mugs Travel Bags Mouse Pad"`} />
                            <meta property="og:site_name" content="Triokzz" />
                            <meta property="og:image:type" content="image/jpg" />

                            <meta property="twitter:card" content={val.product_name} />
                            <meta property="twitter:url" content={`https://www.triokzz.com/organic+grocery/${navItem}/${val.type}+${val.id}`} />
                            <meta property="twitter:title" content={val.product_name + val.type} />
                            <meta property="twitter:description" content={val.product_name + val.product_detail.substr(0, 130)} />
                            <meta property="twitter:image" content={val.url} />
                            <script type="application/ld+json">
                                {`
      {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "${val.product_name} ${val.brand} ${val.type}",
        "alternateName":["${val.type}", "organic", "Trikozz", "Triorgz", "${navItem}"],
        "image": [
            "${val.url}"
        ],
        "description":"${val.type} + ${val.product_detail}",
        "sku": "${val.id}",
        "mpn": "${val.id}-${id}",
        "brand": {
          "@type": "Brand",
          "name": "${val.brand}"
        },
        "review": {
          "@type": "Review",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": ${val.rating},
            "bestRating": 125,
            "ratingCount": 247
          },
          "author": {
            "@type": "Person",
            "name": "Khadke Kunal"
          }
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": ${val.rating},
          "reviewCount": 947
        },
        "offers": {
          "@type": "Offer",
          "url": "${`https://www.triokzz.com/organic+grocery/${navItem}/${val.type}+${val.id}`}",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "price": ${val.offer_prize},
          "priceCurrency": "INR",
          "priceValidUntil": "2024-11-20",
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": 0.0,
              "currency": "INR"
            },
            "shippingDestination": {
              "@type": "DefinedRegion",
              "addressCountry": "IN"
            },
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 0,
                "maxValue": 1,
                "unitCode": "DAY"
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 5,
                "unitCode": "DAY"
              }
            }
          },
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "applicableCountry": "IN",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
            "merchantReturnDays": 30,
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn"
          }
        }
      }
    `}
                            </script>

                            {/* <script src="https://apis.google.com/js/platform.js?onload=renderBadge" async defer></script> */}

                            {/* <script>
                                {`  window.renderBadge = function() {
    var ratingBadgeContainer = document.createElement("div");
    document.body.appendChild(ratingBadgeContainer);
    window.gapi.load('ratingbadge', function() {
      window.gapi.ratingbadge.render(ratingBadgeContainer, {"merchant_id": 5308690872});
    });
  }
    `}

                            </script> */}

                            {/* <!-- Google tag (gtag.js) --> */}
                            <script async="" src="https://www.googletagmanager.com/gtag/js?id=G-RWZEXW6VMP"></script>
                            <script>
                                {
                                    `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "G-RWZEXW6VMP");`
                                }
                            </script>

                            {/* <!-- Google tag (gtag.js) --> */}
                            <script async="" src="https://www.googletagmanager.com/gtag/js?id=AW-11415759693"></script>
                            <script>
                                {
                                    `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "AW-11415759693");`
                                }
                            </script>

                            {/* <!-- Event snippet for Add to basket (1) conversion page --> */}
                            <script>
                                {
                                    `gtag("event", "conversion", {
                                send_to: "AW-11415759693/5sa9CJmbsZEZEM3WusMq",
                                });
                                `
                                }
                            </script>
                            <script>
                                {
                                    `gtag("event", "conversion", {
                                send_to: "AW-11415759693/haFsCPXm5_cYEM3WusMq",
                                });
                                `
                                }
                            </script>
                            {/* <!-- Event snippet for Begin checkout conversion page --> */}
                            {/* <script>
                            {
                                `gtag("event", "conversion", {
                                send_to: "AW-11415759693/QtgvCJ6asZEZEM3WusMq",
                                });`
                            }
                        </script> */}
                            {/* <!-- Event snippet for Purchase conversion page --> */}
                            <script>
                                {`gtag("event", "conversion", {
                                send_to: "AW-11415759693/XSgmCJiasZEZEM3WusMq",
                            transaction_id: "",
                                });
                            `}
                            </script>
                            <script>
                                {
                                    `!(function (f, b, e, v, n, t, s) {
                           if (f.fbq) return;
                            n = f.fbq = function () {
                                n.callMethod
                                    ? n.callMethod.apply(n, arguments)
                                    : n.queue.push(arguments);
                           };
                            if (!f._fbq) f._fbq = n;
                            n.push = n;
                            n.loaded = !0;
                            n.version = "2.0";
                            n.queue = [];
                            t = b.createElement(e);
                            t.async = !0;
                            t.src = v;
                            s = b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t, s);
                            })(
                            window,
                            document,
                            "script",
                            "https://connect.facebook.net/en_US/fbevents.js"
                            );
                            fbq("init", "232698863167434");
                            fbq("track", "PageView");
                            fbq("track", "AddToCart");
                        `}
                            </script>
                            <noscript
                            >
                                {`
                                <img
                                height="1"
                                width="1"
                                style="display: none"
                                src="https://www.facebook.com/tr?id=232698863167434&ev=PageView&noscript=1"
                            />
                         `}
                            </noscript>
                        </Helmet >
                        <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                            {/* carousal  */}

                            {/* end carousal */}
                            <div className="w-full mt-5 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={5}
                                    centeredSlides={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >


                                    <SwiperSlide >

                                        <div className="aspect-h-1 aspect-w-2 overflow-hidden item-center justify-center rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5" >
                                            <img src={val.url} style={{ height: "400px", width: "100%" }} className="aspect-ratio-auto hover:scale-125 transition duration-500 lg:w-1/2" title={val.product_name} alt={val.product_name} />
                                        </div>

                                    </SwiperSlide>
                                    <SwiperSlide >

                                        <div className="aspect-h-1 aspect-w-2 overflow-hidden item-center justify-center rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5" >
                                            <img src={val.detail_url} style={{ height: "400px", width: "100%" }} className="aspect-ratio-auto hover:scale-125 transition duration-500 " title={val.product_name} alt={val.product_name} />
                                        </div>

                                    </SwiperSlide>
                                    <SwiperSlide >

                                        <div className="aspect-h-1 aspect-w-2 overflow-hidden item-center justify-center rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5" >
                                            <img src={val.specified_image} style={{ height: "400px", width: "100%" }} className="aspect-ratio-auto hover:scale-125 transition duration-500 " title={val.product_name} alt={val.product_name} />
                                        </div>

                                    </SwiperSlide>
                                    <SwiperSlide >

                                        <div className="aspect-h-1 aspect-w-2 overflow-hidden item-center justify-center rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5" >
                                            <img src={val.sizeguide_url} style={{ height: "400px", width: "100%" }} className="aspect-ratio-auto hover:scale-125 transition duration-500 " title={val.product_name} alt={val.product_name} />
                                        </div>

                                    </SwiperSlide>


                                </Swiper>
                                {/* <div className="flex item-center justify-center overflow-y-scroll hide-scroll-bar rounded-lg">
                                    <div className="flex flex-nowrap">
                                        <div className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5" >
                                            <img src={val.url} itemProp="contentUrl" style={{ height: "220px", width: "100%", objectFit: "fill" }} className="hover:scale-125 transition duration-500 bg-white drop-shadow-lg rounded-md border border-indigo-300 rounded-2xl col-span-2 col-start-2 max-h-45 w-full object-cover sm:col-start-auto lg:col-span-1" title={val.product_name} alt={val.product_name} />
                                        </div>
                                        <div className="aspect-h-3 aspect-w-2  overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                                            <img src={val.detail_url} style={{ height: "220px", width: "220px", objectFit: "fill" }} className="hover:scale-125 transition duration-500 bg-white drop-shadow-lg rounded-md border border-indigo-300 rounded-2xl col-span-2 col-start-2 max-h-45 w-full object-cover sm:col-start-auto lg:col-span-1" title={val.product_name} alt={val.product_name} />

                                        </div>
                                    </div>
                                </div> */}
                                <div className="sm:col-span-8 lg:col-span-7">

                                    <h1 className="text-2xl font-bold text-gray-900 sm:pr-12">{val.product_name}</h1>

                                    <section aria-labelledby="information-heading" className="mt-2">
                                        <h2 id="information-heading" className="sr-only">
                                            {val.product_name} {val.brand} {val.type}
                                        </h2>

                                        <p className="text-xl text-gray-900">Only at INR {val.offer_prize}.00/-</p>

                                        <p className="text-sm  text-gray-700 line-through">M.R.P. {val.real_prize}.00 /-</p>

                                        <h4 className="text-sm font-medium text-gray-900">Size</h4>
                                        <RadioGroup value={selectedSize} onChange={setSelectedSize} className="mt-4">
                                            <RadioGroup.Label className="sr-only">Choose a size</RadioGroup.Label>
                                            <div className="mr-2 grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2">
                                                {val.size.map((size, i) => (
                                                    <RadioGroup.Option
                                                        key={size}
                                                        value={size}
                                                        disabled={false}
                                                        className={({ active }) =>
                                                            classNames(
                                                                size.inStock
                                                                    ? 'cursor-pointer bg-white text-gray-900 shadow-sm'
                                                                    : 'w-32 cursor-pointer bg-gray-50 text-gray-500',
                                                                active ? 'ring-2 ring-indigo-500' : '',
                                                                'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                                            )
                                                        }
                                                    >
                                                        {({ active, checked }) => (
                                                            <>
                                                                <RadioGroup.Label as="span" style={{ lineBreak: "anywhere" }}>{size}</RadioGroup.Label>
                                                                {size ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active ? 'border' : 'border-2',
                                                                            checked ? 'border-indigo-500' : 'border-transparent',
                                                                            'pointer-events-auto absolute -inset-px rounded-md'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    />
                                                                ) : (
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className="pointer-events-auto absolute -inset-px rounded-md border-2 border-gray-200"
                                                                    >
                                                                        <svg
                                                                            className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                                                            viewBox="0 0 100 100"
                                                                            preserveAspectRatio="none"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                                                                        </svg>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                    </RadioGroup.Option>
                                                ))}
                                            </div>
                                        </RadioGroup>

                                        <hr></hr>
                                        {/* <div class="flex  custom-number-input h-10 w-48">
                                            <label for="custom-input-number" class="mt-1 w-full text-gray-700 text-sm font-semibold">
                                                Select Quantity
                                            </label>
                                            <div class="flex flex-row h-5 w-20 rounded-lg relative bg-transparent mt-1">
                                                <button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button">

                                                    {quantity}
                                                </button>
                                                <select id="countries" onChange={(e) => {
                                                    setQuantity(e.target.value)
                                                }} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                    <option defaultValue={val.count ? val.count : 1} >1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                            </div>

                                        </div> */}
                                        {/* <div class="flex "><p className="pl-3 text-2xl text-gray-900">Total : <span>{quantity * val.offer_prize}.00 /-</span></p> </div> */}
                                        <button
                                            // type="submit"
                                            className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent shadow-2xl  bg-gray-900 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => checkout([{
                                                name: val.brand,
                                                price: val.offer_prize,
                                                id: val.id,
                                                count: val.count,
                                                rating: 4.5,
                                                reviewCount: 117,
                                                imageSrc: val.url,
                                                imageAlt: val.brand,
                                                colors: { name: selectedColor, class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
                                                sizes: { name: selectedSize, inStock: true },
                                                type: val.type,
                                                detail: val.product_name,
                                                delivery_charge: val.delivery_charge
                                            }])}
                                        >
                                            Buy Now
                                        </button>
                                        <ul class="mb-8 mt-8 space-y-6 text-left text-lg text-gray-500">
                                            <li class="flex items-center space-x-4">

                                                <svg class="flex-shrink-0 w-6 h-6 text-indigo-600" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Trusted Brand By Triokzz</span>
                                            </li>
                                            <li class="flex items-center space-x-4">

                                                <svg class="flex-shrink-0 w-6 h-6 text-indigo-600" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>Easy Return on damaged/wrong shipping</span>
                                            </li>
                                            <li class="flex items-center space-x-4">

                                                <svg class="flex-shrink-0 w-6 h-6 text-indigo-600" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>24/7 Customer support </span>
                                            </li>
                                            <li class="flex items-center space-x-4">

                                                <svg class="flex-shrink-0 w-6 h-6 text-indigo-600" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>020-47153-653</span>
                                            </li>
                                        </ul>
                                        {/* Reviews */}
                                        <div className="mt-6 mb-6">
                                            <h4 className="sr-only">Reviews</h4>
                                            <div className="flex items-center">
                                                <div className="flex items-center">
                                                    {count.map((rating) => (
                                                        <StarIcon
                                                            key={rating}
                                                            className={classNames(
                                                                val.rating > rating ? 'text-green-900' : 'text-gray-200',
                                                                'h-5 w-5 flex-shrink-0'
                                                            )}
                                                            aria-hidden="true"
                                                            onClick={() => setarr(new Array(rating + 1).fill(null).map((_, i) => i))}
                                                        />
                                                    ))}
                                                </div>
                                                <p className="sr-only">{val.rating} out of 5 stars</p>
                                                <a href="https://g.page/r/CYWYfi5lwEcbEBM/review" className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                                    {product.reviewCount} reviews
                                                </a>
                                            </div>
                                            {/* rating bar  */}
                                            <div class="flex items-center mt-4">
                                                <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">5 star</a>
                                                <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                                                    <div class="h-5 bg-yellow-300 rounded" style={{ width: "70%" }}></div>
                                                </div>
                                                <span class="text-sm font-medium text-gray-500 dark:text-gray-400">70%</span>
                                            </div>
                                            <div class="flex items-center mt-4">
                                                <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">4 star</a>
                                                <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                                                    <div class="h-5 bg-yellow-300 rounded" style={{ width: "17%" }}></div>
                                                </div>
                                                <span class="text-sm font-medium text-gray-500 dark:text-gray-400">17%</span>
                                            </div>
                                            <div class="flex items-center mt-4">
                                                <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">3 star</a>
                                                <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                                                    <div class="h-5 bg-yellow-300 rounded" style={{ width: "8%" }}></div>
                                                </div>
                                                <span class="text-sm font-medium text-gray-500 dark:text-gray-400">8%</span>
                                            </div>
                                            <div class="flex items-center mt-4">
                                                <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">2 star</a>
                                                <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                                                    <div class="h-5 bg-yellow-300 rounded" style={{ width: "4%" }}></div>
                                                </div>
                                                <span class="text-sm font-medium text-gray-500 dark:text-gray-400">4%</span>
                                            </div>
                                            <div class="flex items-center mt-4">
                                                <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">1 star</a>
                                                <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                                                    <div class="h-5 bg-yellow-300 rounded" style={{ width: "4%" }}></div>
                                                </div>
                                                <span class="text-sm font-medium text-gray-500 dark:text-gray-400">1%</span>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div class="flex">
                                            <div class="flex items-center h-5">
                                                <input id="helper-radio" aria-describedby="helper-radio-text" type="radio" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            </div>
                                            <div class="ms-2 text-sm">
                                                <label for="helper-radio" class="font-medium text-gray-900 dark:text-gray-300">Free delivery on rs.1449</label>
                                                <p id="helper-radio-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">For order within a day</p>
                                            </div>
                                        </div>
                                    </section>
                                    <hr></hr>

                                    <section aria-labelledby="options-heading" className="mt-10">
                                        <h3 id="options-heading" className="sr-only">
                                            Product options
                                        </h3>

                                        {/* <form> */}
                                        {/* Colors */}
                                        <div className="flex">


                                            <RadioGroup value={selectedColor} onChange={setSelectedColor} className="mt-4">
                                                <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                                                <span className="flex items-center space-x-3">
                                                    <h4 className="text-sm font-medium text-gray-900">Color: {val.color}</h4>
                                                </span>
                                            </RadioGroup>

                                        </div>
                                        <div>
                                            {val.type.includes("Tshirt") ?
                                                <img src={Sizeguard} alt="you tees, womens basic tee shirt, indoor decor, home decor items near me, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags" className="object-cover object-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer" /> :
                                                val.type.includes("Ceramic" || "Mug") ?
                                                    <img src={PrintedMugDetail} alt="you tees, womens basic tee shirt, indoor decor, home decor items near me, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags" className="object-cover object-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer" /> :
                                                    <iframe width="100%" height="315" src="https://shopnowindia.s3.ap-south-1.amazonaws.com/advertising+video/Matrix+Promotion+(1).mp4"></iframe>}
                                        </div>
                                        {/* Sizes */}
                                        <div className="mt-10">
                                            <div className="flex items-center justify-between">
                                                {/* <h4 className="text-sm font-medium text-gray-900">Size</h4> */}
                                                {val.brand.toLowerCase().includes("jigari") ? <a href="https://www.wikidata.org/wiki/Q131151" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                                    Size guide
                                                </a> : val.type.includes("Ceramic" || "Mug") ? <a href="https://www.wikidata.org/wiki/Q13464614">Printed Mugs</a> : <a href="https://www.wikidata.org/wiki/Q467505">Handbags or Decor</a>}
                                                {/* <Sizeguard /> */}
                                            </div>

                                        </div>

                                        <div class="flex">
                                            <p className="flex items-center mb-3 text-gray-800 dark:text-gray-400 first-line:uppercase first-line:tracking-widest first-letter:text-7xl first-letter:font-bold first-letter:text-gray-900 dark:first-letter:text-gray-300 first-letter:me-3 first-letter:float-start">
                                                {val.product_detail}

                                            </p>
                                        </div>
                                        <hr></hr>
                                        <div className="p-1 flex items-center justify-between">
                                            <h4 className="text-sm font-medium text-gray-900"> Ships in 4-7 days </h4>
                                            <a href="/privacy" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                                Agree Policy
                                            </a>
                                            {/* <Sizeguard /> */}
                                        </div>

                                        {/* </form> */}
                                    </section>
                                </div>
                            </div>
                        </div>

                    </div>
                )
            })}
            <div className="w-full">
                <section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
                    {/* <section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> */}
                    <div id="tabs" className="flex justify-between">
                        <a href="https://www.triokzz.com/product/printed" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M21.0847458,3.38674884 C17.8305085,7.08474576 17.8305085,10.7827427 21.0847458,14.4807396 C24.3389831,18.1787365 24.3389831,22.5701079 21.0847458,27.6548536 L21.0847458,42 L8.06779661,41.3066256 L6,38.5331279 L6,26.2681048 L6,17.2542373 L8.88135593,12.4006163 L21.0847458,2 L21.0847458,3.38674884 Z" fill="currentColor" fill-opacity="0.1"></path>
                                    <path d="M11,8 L33,8 L11,8 Z M39,17 L39,36 C39,39.3137085 36.3137085,42 33,42 L11,42 C7.6862915,42 5,39.3137085 5,36 L5,17 L7,17 L7,36 C7,38.209139 8.790861,40 11,40 L33,40 C35.209139,40 37,38.209139 37,36 L37,17 L39,17 Z" fill="currentColor"></path>
                                    <path d="M22,27 C25.3137085,27 28,29.6862915 28,33 L28,41 L16,41 L16,33 C16,29.6862915 18.6862915,27 22,27 Z" stroke="currentColor" stroke-width="2" fill="currentColor" fill-opacity="0.1"></path>
                                    <rect fill="currentColor" transform="translate(32.000000, 11.313708) scale(-1, 1) rotate(-45.000000) translate(-32.000000, -11.313708) " x="17" y="10.3137085" width="30" height="2" rx="1"></rect>
                                    <rect fill="currentColor" transform="translate(12.000000, 11.313708) rotate(-45.000000) translate(-12.000000, -11.313708) " x="-3" y="10.3137085" width="30" height="2" rx="1"></rect>
                                </g>
                            </svg>
                            <span class="sr-only"><a href="https://www.wikidata.org/wiki/Q13464614">Printed Mug, Coffee Mugs</a>, Panda Printed Mug Call Experience the joy of printo art every morning with our amazing Artistic Expression.Our Amazon bulk Printed Mugs, Ceramic Items a beautiful piece of artwork that wraps around its exterior, turning an everyday item into a piece of art.
                                Save your amazing power looks items and flip to cart now our brands and products -Triokzz, JiGari, Ted baker, fasion, kaylazzk, unique art print mugs.
                            </span>

                            <span className="tab tab-home block text-xs">Printed Hub</span>
                        </a>
                        <a href="https://www.triokzz.com/product/womens" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M14.7118754,20.0876892 L8.03575361,20.0876892 C5.82661462,20.0876892 4.03575361,18.2968282 4.03575361,16.0876892 L4.03575361,12.031922 C4.03575361,8.1480343 6.79157254,4.90780265 10.4544842,4.15995321 C8.87553278,8.5612583 8.1226025,14.3600511 10.9452499,15.5413938 C13.710306,16.6986332 14.5947501,18.3118357 14.7118754,20.0876892 Z M14.2420017,23.8186831 C13.515543,27.1052019 12.7414284,30.2811559 18.0438552,31.7330419 L18.0438552,33.4450645 C18.0438552,35.6542035 16.2529942,37.4450645 14.0438552,37.4450645 L9.90612103,37.4450645 C6.14196811,37.4450645 3.09051926,34.3936157 3.09051926,30.6294627 L3.09051926,27.813861 C3.09051926,25.604722 4.88138026,23.813861 7.09051926,23.813861 L14.0438552,23.813861 C14.1102948,23.813861 14.1763561,23.8154808 14.2420017,23.8186831 Z M20.7553776,32.160536 C23.9336213,32.1190063 23.9061943,29.4103976 33.8698747,31.1666916 C34.7935223,31.3295026 35.9925894,31.0627305 37.3154077,30.4407183 C37.09778,34.8980343 33.4149547,38.4450645 28.9036761,38.4450645 C24.9909035,38.4450645 21.701346,35.7767637 20.7553776,32.160536 Z" fill="currentColor" opacity="0.1"></path>
                                    <g transform="translate(2.000000, 3.000000)">
                                        <path d="M8.5,1 C4.35786438,1 1,4.35786438 1,8.5 L1,13 C1,14.6568542 2.34314575,16 4,16 L13,16 C14.6568542,16 16,14.6568542 16,13 L16,4 C16,2.34314575 14.6568542,1 13,1 L8.5,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M4,20 C2.34314575,20 1,21.3431458 1,23 L1,27.5 C1,31.6421356 4.35786438,35 8.5,35 L13,35 C14.6568542,35 16,33.6568542 16,32 L16,23 C16,21.3431458 14.6568542,20 13,20 L4,20 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M23,1 C21.3431458,1 20,2.34314575 20,4 L20,13 C20,14.6568542 21.3431458,16 23,16 L32,16 C33.6568542,16 35,14.6568542 35,13 L35,8.5 C35,4.35786438 31.6421356,1 27.5,1 L23,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M34.5825451,33.4769886 L38.3146092,33.4322291 C38.8602707,33.4256848 39.3079219,33.8627257 39.3144662,34.4083873 C39.3145136,34.4123369 39.3145372,34.4162868 39.3145372,34.4202367 L39.3145372,34.432158 C39.3145372,34.9797651 38.8740974,35.425519 38.3265296,35.4320861 L34.5944655,35.4768456 C34.048804,35.4833899 33.6011528,35.046349 33.5946085,34.5006874 C33.5945611,34.4967378 33.5945375,34.4927879 33.5945375,34.488838 L33.5945375,34.4769167 C33.5945375,33.9293096 34.0349773,33.4835557 34.5825451,33.4769886 Z" fill="currentColor" transform="translate(36.454537, 34.454537) rotate(-315.000000) translate(-36.454537, -34.454537) "></path>
                                        <circle stroke="currentColor" stroke-width="2" cx="27.5" cy="27.5" r="7.5"></circle>
                                    </g>
                                </g>
                            </svg>
                            <span class="sr-only"><a href="https://www.wikidata.org/wiki/Q131151" >Buy Womens Solid Tshirt</a>. Experience ultimate comfort and style with our Tshirt. Comfort Unisex Plain T-Shirt. Save your amazing power looks items and flip to cart now our brands and products -Triokzz, JiGari, Ted baker, fasion, kaylazzk, unique art print mugs, Bewakoofy items, home decor items, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags</span>
                            <span className="tab tab-kategori block text-xs">Womens/Mens</span>
                        </a>
                        <a href="https://www.triokzz.com/product/travelling" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M20.5890101,0.254646884 C12.8696785,5.50211755 8.0025785,14.258415 14.1941217,18.8708225 C23.16683,25.5550669 13.3362326,40.2698884 33.1021758,38.4149164 C29.6814884,40.8311956 25.5065164,42.2507054 21,42.2507054 C9.40202025,42.2507054 0,32.8486852 0,21.2507054 C0,9.79003409 9.18071714,0.473634138 20.5890101,0.254646884 Z" fill="currentColor" opacity="0.1"></path>
                                    <path d="M25.9500282,20.3643496 L22.4308312,38.2677802 C22.3775703,38.5387376 22.1147395,38.7152155 21.8437821,38.6619546 C21.6570955,38.6252584 21.507413,38.4857901 21.4576354,38.3021581 L16.5951895,20.3643496 L20.099732,4.44663907 C20.1385204,4.27046145 20.2692032,4.12883813 20.4417012,4.07604096 C20.7057521,3.99522179 20.9853245,4.14376046 21.0661436,4.40781135 L25.9500282,20.3643496 Z M21.3022963,22.2852638 C22.4068658,22.2852638 23.3022963,21.3898333 23.3022963,20.2852638 C23.3022963,19.1806943 22.4068658,18.2852638 21.3022963,18.2852638 C20.1977268,18.2852638 19.3022963,19.1806943 19.3022963,20.2852638 C19.3022963,21.3898333 20.1977268,22.2852638 21.3022963,22.2852638 Z" fill="currentColor" transform="translate(21.272609, 20.629524) rotate(-315.000000) translate(-21.272609, -20.629524) "></path>
                                    <circle stroke="currentColor" stroke-width="2" cx="21" cy="21" r="20"></circle>
                                </g>
                            </svg>
                            <span class="sr-only">Meet your perfect travel <a href="https://www.wikidata.org/wiki/Q467505">bags, purse, handbags</a> companion meesh with ted beker, the Globetrotter’s Companion Travel Purse. Designed with the modern traveler in mind, this compact purse keeps your essentials organized and secure, whether you’re exploring a new city or running errands in your hometown.sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags</span>

                            <span className="tab tab-explore block text-xs">Explore Travel</span>
                        </a>
                        <a href="https://www.triokzz.com/watchtv" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M14.7118754,20.0876892 L8.03575361,20.0876892 C5.82661462,20.0876892 4.03575361,18.2968282 4.03575361,16.0876892 L4.03575361,12.031922 C4.03575361,8.1480343 6.79157254,4.90780265 10.4544842,4.15995321 C8.87553278,8.5612583 8.1226025,14.3600511 10.9452499,15.5413938 C13.710306,16.6986332 14.5947501,18.3118357 14.7118754,20.0876892 Z M14.2420017,23.8186831 C13.515543,27.1052019 12.7414284,30.2811559 18.0438552,31.7330419 L18.0438552,33.4450645 C18.0438552,35.6542035 16.2529942,37.4450645 14.0438552,37.4450645 L9.90612103,37.4450645 C6.14196811,37.4450645 3.09051926,34.3936157 3.09051926,30.6294627 L3.09051926,27.813861 C3.09051926,25.604722 4.88138026,23.813861 7.09051926,23.813861 L14.0438552,23.813861 C14.1102948,23.813861 14.1763561,23.8154808 14.2420017,23.8186831 Z M20.7553776,32.160536 C23.9336213,32.1190063 23.9061943,29.4103976 33.8698747,31.1666916 C34.7935223,31.3295026 35.9925894,31.0627305 37.3154077,30.4407183 C37.09778,34.8980343 33.4149547,38.4450645 28.9036761,38.4450645 C24.9909035,38.4450645 21.701346,35.7767637 20.7553776,32.160536 Z" fill="currentColor" opacity="0.1"></path>
                                    <g transform="translate(2.000000, 3.000000)">
                                        <path d="M8.5,1 C4.35786438,1 1,4.35786438 1,8.5 L1,13 C1,14.6568542 2.34314575,16 4,16 L13,16 C14.6568542,16 16,14.6568542 16,13 L16,4 C16,2.34314575 14.6568542,1 13,1 L8.5,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M4,20 C2.34314575,20 1,21.3431458 1,23 L1,27.5 C1,31.6421356 4.35786438,35 8.5,35 L13,35 C14.6568542,35 16,33.6568542 16,32 L16,23 C16,21.3431458 14.6568542,20 13,20 L4,20 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M23,1 C21.3431458,1 20,2.34314575 20,4 L20,13 C20,14.6568542 21.3431458,16 23,16 L32,16 C33.6568542,16 35,14.6568542 35,13 L35,8.5 C35,4.35786438 31.6421356,1 27.5,1 L23,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M34.5825451,33.4769886 L38.3146092,33.4322291 C38.8602707,33.4256848 39.3079219,33.8627257 39.3144662,34.4083873 C39.3145136,34.4123369 39.3145372,34.4162868 39.3145372,34.4202367 L39.3145372,34.432158 C39.3145372,34.9797651 38.8740974,35.425519 38.3265296,35.4320861 L34.5944655,35.4768456 C34.048804,35.4833899 33.6011528,35.046349 33.5946085,34.5006874 C33.5945611,34.4967378 33.5945375,34.4927879 33.5945375,34.488838 L33.5945375,34.4769167 C33.5945375,33.9293096 34.0349773,33.4835557 34.5825451,33.4769886 Z" fill="currentColor" transform="translate(36.454537, 34.454537) rotate(-315.000000) translate(-36.454537, -34.454537) "></path>
                                        <circle stroke="currentColor" stroke-width="2" cx="27.5" cy="27.5" r="7.5"></circle>
                                    </g>
                                </g>
                            </svg>
                            <span class="sr-only">Save your amazing power looks items and flip to cart now</span>

                            <span className="tab tab-whishlist block text-xs">Triokzz Tv</span>
                        </a>

                    </div>
                </section>
            </div>
        </div>

    )
}
export default ProductPage;