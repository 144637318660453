import { useCallback, useState, useEffect } from 'react'
// import { Dialog, Popover, Tab, Transition, Menu } from '@headlessui/react'
import ProductNav from './../pages/productNav'
import ProductDialog from './productdialog'
import ShoppingCard from './shippingcard'
import { useSelector, useDispatch } from 'react-redux';
import { getGroceryProducts, AddtoGROCERYList, getProductsforDecore } from './../Redux/action'
import { Helmet } from 'react-helmet-async';
import {
    useParams, useNavigate
} from "react-router-dom";
// import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import axios from 'axios'
import { Fragment } from 'react'
// import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Logo from './../assist/triokzzlohgo.svg';
import Loadinglogo from './../assist/loadinglogo.png';
import OfferImg from '../assist/offeroffer.jpg';
import Hnadcrafted from '../assist/handicrafted_item.jpg';
import BackgroundFrame from '../assist/backgroundframe.jpg';
import Bagimg from '../assist/bag.jpg';
import PrintedMug from '../assist/PrintedMug.png';
import firebase from 'firebase/compat/app';
import "firebase/compat/messaging";
import { getMessaging, getToken } from "firebase/messaging";
import { analytics, messaging } from './../Notification/firebase.js'
import { onMessage } from 'firebase/messaging';
import toast, { Toaster } from 'react-hot-toast';

// import addNotification from 'react-push-notification';

// import Chatbot from './chatbot'
import FirstAd from '../assist/ad1.png';
import SecondAd from '../assist/ad2.png';
import ThirdAd from '../assist/ad3.png';
import ForthAd from '../assist/ad4.jpg';
import review1 from '../assist/review1.png';
import review2 from '../assist/review2.png';
import review3 from '../assist/review3.png';
import review4 from '../assist/review4.png';
import review5 from '../assist/review5.png';
import review6 from '../assist/review6.png';
import review7 from '../assist/review7.png';
import OfferBanner from '../assist/OfferBanner.jpg';
// import './Celebration.css'
// import OfferCard from './offer.js'


const Advertise = [

    { id: 0, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 1, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 2, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 3, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 4, imgSrc: Hnadcrafted },
    { id: 5, imgSrc: review5 },
    // { id: 6, imgSrc: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Clothing_store_banner.JPG/1920px-Clothing_store_banner.JPG" },
    { id: 7, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 8, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 9, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 10, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
    { id: 11, imgSrc: "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const GroceryList = (props) => {
    const { main, subproduct } = useParams()
    const [subNavItem, setSubNavItem] = useState(subproduct)
    const [dialog, setDialog] = useState(false)
    const [cardProduct, setCardProduct] = useState({})
    const [addtocard, setAddtocard] = useState([])
    const [openshopping, setOpenShopping] = useState(false)
    const [navItem, setNavItem] = useState(main)
    const [serchableoutput, setserchableoutput] = useState([])
    const [confirmModal, setConfirmModal] = useState(false)
    const [menuproduct, setMenuProduct] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getProducts = async (navItem) => {

        let TrendingProducts = await axios.get("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/organic_products")
            .then(r => r)
            .then(res => res.data)
            .catch(err => console.log(err))
        setMenuProduct(TrendingProducts)
    };
    useEffect(() => {
        getProducts("Millets")
    }, [])

    const onSelectNavItem = (item) => {
        if (item == "watchtv") {
            navigate(`/watchtv`)
        } else {
            setNavItem(item)
        }

        // navigate(`/${item}`)
    }


    // const handlePopstate = (event) => {
    //     // Your logic to handle the back button click
    //     // event.preventDefault();
    //     alert("are you sure")
    //     window.history.pushState({}, "", "grocery/products")
    //     window.history.pushState({}, "", "list/grocery/Millets")
    // };
    // useEffect(() => {
    //     window.addEventListener('popstate', handlePopstate)
    //     return window.removeEventListener('popstate', handlePopstate)
    // }, [window.location])


    // get data from redux
    let GroceryProducts = []
    GroceryProducts = useSelector(state => state.allProducts.grocery)

    // dispatch action 
    const scrolltobottom = () => {
        window.scrollTo(0, 1000)
    }
    useEffect(() => {

        dispatch(getGroceryProducts(navItem))
        // scrolltobottom()
    }, [navItem])



    const onshoppingRender = useCallback(() => {
        // navigate("product/shoppingCard")
        return setOpenShopping(true)
    }, [])

    const onOpen = (product, shopping_card_id) => {

        let somthing = addtocard && addtocard.find((val) => {
            // console.log(val)
            return val.id == product.id
        })
        if (!product.freez && somthing == undefined) {
            setDialog(!dialog)
            setCardProduct(product)
            console.log("render", shopping_card_id)
        } else {
            if (shopping_card_id >= 0) {
                const forSizechange = {
                    brand: product && product.name,
                    color: product && product.colors && product.colors.name,
                    count: 1,
                    detail_url: product && product.detail_url,
                    freez: false,
                    id: product && product.id,
                    offer_prize: product && product.price,
                    product_detail: product && product.detail,
                    product_name: product && product.product_name,
                    quantity: "10",
                    rating: product && product.rating,
                    real_prize: product && product.real_prize,
                    size: product && product.size,
                    type: product && product.type,
                    uni_productId: 1317,
                    url: product && product.imageSrc,
                    delivery_charge: product && product.delivery_charge
                }
                setCardProduct(forSizechange)
                setTimeout(() => {
                    setDialog(!dialog)
                })
            } else {
                onshoppingRender()
            }
        }
    }


    const onfilter = async (serchinput) => {
        // console.log("asasa")
        let searchableoutput = []
        let serching = serchinput && serchinput.toLowerCase() || subNavItem.toLowerCase()
        if (serching && serching.length > 2) {
            let TrendingProductsforsearch = await axios.get("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/organic_products")
                .then(r => r)
                .then(res => res.data)
                .catch(err => console.log(err))


            let productaction = TrendingProductsforsearch && TrendingProductsforsearch.products && TrendingProductsforsearch.products.find((val, i, arr) => {
                return val.product_for == navItem
            })

            productaction.section.map((value, index) => {
                let [keys] = Object.keys(value)
                let arr = value[keys]
                let hello = arr.map(val => val)

                const filteredval = hello.filter(info => {

                    return info && info.product_detail && info.product_detail.toLowerCase() && info.product_detail.toLowerCase().includes(serching)
                }
                )
                console.log(filteredval)
                if (filteredval.length > 0) {
                    setserchableoutput(filteredval)
                }

                return filteredval
            })

        }

    }




    // added items
    const onAddToCard = (cardproducts) => {
        let somthing = addtocard && addtocard.find((val) => {
            return val.id == cardproducts.id
        })

        if (somthing) {
            let filtereditem = addtocard && addtocard.filter((val) => {
                return val.id !== cardproducts.id
            })
            let items = filtereditem.concat(cardproducts)
            setAddtocard(items)
            localStorage.setItem("addtoGrocerycard", JSON.stringify(items))
            dispatch(AddtoGROCERYList(items))
            onshoppingRender()
        } else
            if (somthing == undefined) {
                let items = addtocard && addtocard.concat(cardproducts)
                setAddtocard(items)
                localStorage.setItem("addtoGrocerycard", JSON.stringify(items))
                dispatch(AddtoGROCERYList(items))
                onshoppingRender()
            } else {
                onshoppingRender()
            }
        analytics.logEvent('add_to_cart')
        const message = {
            notification: {
                title: '$FooCorp up 1.43% on the day',
                body: '$FooCorp gained 11.80 points to close at 835.67, up 1.43% on the day.'
            },
            webpush: {
                fcm_options: {
                    link: "https://triokzz.com"
                }
            }
        };
        toast(
            <span>
                <b>add to cart</b>
            </span>,
            {
                icon: '🔥',
            }
        )
    }

    // qantity of the product
    const onCount = (e, id, index) => {
        var a = addtocard && addtocard.find((val, i) => val.id == id)
        // console.log(a.id, id)
        if (a.id == id) {
            addtocard[index].count = e.target.value
            localStorage.setItem("addtoGrocerycard", JSON.stringify(addtocard))
        }

    }

    const onRemove = (item, index) => {
        const removeItem = addtocard && addtocard.filter((value, i) => {
            return i !== index
        })
        setAddtocard(removeItem)
        localStorage.setItem("addtoGrocerycard", JSON.stringify(removeItem))
        dispatch(AddtoGROCERYList(removeItem))
    }

    useEffect(() => {

        const getitem = localStorage.getItem("addtoGrocerycard")
        if (getitem != null) {
            setAddtocard(JSON.parse(getitem))
            dispatch(AddtoGROCERYList(JSON.parse(getitem)))
        }

    }, [])
    useEffect(() => {
        setTimeout(() => {
            sessionStorage.setItem("addtoGrocerycard", JSON.stringify(addtocard))
        }, 100)

    }, [addtocard])


    return (
        <div className="bg-white justify-center">

            <Helmet>
                <title>{navItem}</title>
                <meta name="description"
                    content={`"We are selling partner, ${navItem} On the Organic Supermarts Triokzz focuses on empowering ORGANIC PRODUCTS sellers across India."`}>
                </meta>
                <meta name="robots" content="index, follow" />
                <meta name="robots" content="max-image-preview:large" />
                <meta name="google-site-verification" content="vwUAwcHi4pY-Lgm6a9JJyOwn1GLbkA7gO6fvQOy4FvU" />
                <meta name="keywords" content={`${navItem}, Organic Products, empower, Go Organic, Organic soil product, Triorgz`}></meta>
                <link rel="canonical" href={`https://www.triokzz.com/list/grocery/${navItem}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={navItem} />
                <meta property="og:description" content={`"We are selling partner, ${navItem} On the Organic Supermarts Triokzz focuses on empowering ORGANIC PRODUCTS sellers across India."`} />
                <meta property="og:image" content="https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" />
                <meta property="og:image:height" content="1200" />
                <meta property="og:image:width" content="630" />
                <meta property="og:url" content={`https://www.triokzz.com/list/grocery/${navItem}`} />
                <meta property="og:image:alt" content={`"${navItem} organic product"`} />
                <meta property="og:site_name" content="Triokzz" />
                <meta property="og:image:type" content="image/jpg" />

                <meta property="twitter:card" content={navItem} />
                <meta property="twitter:url" content={`https://www.triokzz.com/list/grocery/${navItem}`} />
                <meta property="twitter:title" content={navItem} />
                <meta property="twitter:description" content={`"We are selling partner, ${navItem} On the Organic Supermarts Triokzz focuses on empowering ORGANIC PRODUCTS sellers across India."`} />
                <meta property="twitter:image" content="https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png" />

                {/* <script src="https://apis.google.com/js/platform.js?onload=renderBadge" async defer></script> */}

                {/* <script>
                                {`  window.renderBadge = function() {
    var ratingBadgeContainer = document.createElement("div");
    document.body.appendChild(ratingBadgeContainer);
    window.gapi.load('ratingbadge', function() {
      window.gapi.ratingbadge.render(ratingBadgeContainer, {"merchant_id": 5308690872});
    });
  }
    `}

                            </script> */}

                {/* <!-- Google tag (gtag.js) --> */}
                <script async="" src="https://www.googletagmanager.com/gtag/js?id=G-RWZEXW6VMP"></script>
                <script>
                    {
                        `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "G-RWZEXW6VMP");`
                    }
                </script>

                {/* <!-- Google tag (gtag.js) --> */}
                <script async="" src="https://www.googletagmanager.com/gtag/js?id=AW-11415759693"></script>
                <script>
                    {
                        `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "AW-11415759693");`
                    }
                </script>

                {/* <!-- Event snippet for Add to basket (1) conversion page --> */}
                <script>
                    {
                        `gtag("event", "conversion", {
                                send_to: "AW-11415759693/5sa9CJmbsZEZEM3WusMq",
                                });
                                `
                    }
                </script>
                <script>
                    {
                        `gtag("event", "conversion", {
                                send_to: "AW-11415759693/haFsCPXm5_cYEM3WusMq",
                                });
                                `
                    }
                </script>
                {/* <!-- Event snippet for Begin checkout conversion page --> */}
                {/* <script>
                            {
                                `gtag("event", "conversion", {
                                send_to: "AW-11415759693/QtgvCJ6asZEZEM3WusMq",
                                });`
                            }
                        </script> */}
                {/* <!-- Event snippet for Purchase conversion page --> */}
                <script>
                    {`gtag("event", "conversion", {
                                send_to: "AW-11415759693/XSgmCJiasZEZEM3WusMq",
                            transaction_id: "",
                                });
                            `}
                </script>
                <script>
                    {
                        `!(function (f, b, e, v, n, t, s) {
                           if (f.fbq) return;
                            n = f.fbq = function () {
                                n.callMethod
                                    ? n.callMethod.apply(n, arguments)
                                    : n.queue.push(arguments);
                           };
                            if (!f._fbq) f._fbq = n;
                            n.push = n;
                            n.loaded = !0;
                            n.version = "2.0";
                            n.queue = [];
                            t = b.createElement(e);
                            t.async = !0;
                            t.src = v;
                            s = b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t, s);
                            })(
                            window,
                            document,
                            "script",
                            "https://connect.facebook.net/en_US/fbevents.js"
                            );
                            fbq("init", "232698863167434");
                            fbq("track", "PageView");
                            fbq("track", "AddToCart");
                        `}
                </script>
                <noscript
                >
                    {`
                                <img
                                height="1"
                                width="1"
                                style="display: none"
                                src="https://www.facebook.com/tr?id=232698863167434&ev=PageView&noscript=1"
                            />
                         `}
                </noscript>
            </Helmet >

            <ProductNav {...props} onSelectNavItem={onSelectNavItem} onfilter={onfilter} onshoppingRender={onshoppingRender} selectedItem={addtocard && addtocard.length} />
            <div className="flex  overflow-y-scroll pb-2 hide-scroll-bar rounded-lg">
                <ul className="flex flex-nowrap">
                    {menuproduct && menuproduct.products && menuproduct.products.map((val, i) => {
                        return (<li key={val.product_id}
                            className="bg-lime-100  p-1 flex items-center flex-col  hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-1 py-1 text-center me-2 m-2"
                        >
                            <a href={`/list/grocery/${val.product_for}`}>
                                <img src={val.image ? val.image : "https://shopnowindia.s3.ap-south-1.amazonaws.com/organic_product/Triorg.png"} alt="Trendy Products" className="ml-4 rounded-full w-16 h-16 object-cover" title="Decorate your home with triokzz" />
                                <h5 className="capitalize truncate text-sm w-24 text-sky-600">{val.product_for}</h5>
                            </a>
                        </li>)
                    }
                    )
                    }
                </ul>
            </div>
            <hr class="border-b-2 rounded-md drop-shadow-lg "></hr>
            <div className="scroll-left flex bg-blue-900">
                <header>
                    <h1 className="flex capitalize text-lg font-medium text-white"> One-day delivery may be available if the seller and buyer are within the same city. Trendy {navItem} Products.Best Selective {navItem} Products Trusted By The World’s Most Innovative Teams</h1>

                </header>
            </div>

            <div className="relative mx-auto max-w-2xl px-2 py-2 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8  " >
                <div className="mt-2 mr-2 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 xl:gap-x-2">
                    {serchableoutput.length > 0 ? serchableoutput.map((val, id) => {

                        return (
                            <div key={val.uni_productId} className={val.freez ? "group relative cursor-not-allowed transformed opacity-25 bg-white flex" : "group relative bg-white flex"} onClick={() => onOpen(val)}>

                                <div className="aspect-h-1 aspect-w-1 w-48 overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-48">
                                    <img
                                        src={val.url}
                                        alt={val.type}
                                        style={{ height: "190px", width: "100%", objectFit: "fill" }}
                                        className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                                        title={val.product_name}
                                    />
                                    {val.freez && <span class="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-green-500 relative inline-block">
                                        <span class="relative text-white">Sold out</span>
                                    </span>}
                                </div>
                                <div className="mt-8 justify-between w-48">
                                    <div>
                                        <h3 className="text-md text-gray-700">
                                            <span aria-hidden="true" className="absolute inset-0" />
                                            {val.brand}
                                        </h3>
                                        <p className="mt-1 text-md text-gray-500">{val.color}</p>
                                    </div>

                                    <div>
                                        <h3 className="text-sm mt-4 font-medium text-green"><span className="text-md text-gray-700">Offer Price:</span>{val.offer_prize}</h3>
                                        <p className="text-sm font-medium text-red-900 line-through"><span className="text-sm text-gray-700">Real Price:</span>{val.real_prize}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <div className="relative  grid grid-cols-1 w-full" >
                        {/* <div className={navItem == "all" ? "container p-2 " : "container p-2 hidden"}>
                            <img src="https://static.sadhguru.org/d/46272/1646819925-cp-banner.jpg" className="col-span-2 col-start-2 max-h-28 w-full object-contain sm:col-start-auto lg:col-span-1" alt="Save Soil Save Life" title="Save Soil Save Life" />
                            <p className="ml-2 capitalize text-sm font-medium text-white font-semibold dark:text-white font-serif">We know who's gonna shine brighten this season, do you know who ? Its you .😃.  Because, all sale is here with best deals on latest styles 🫣</p>
                        </div> */}
                        <div className="bg-white py-2 sm:py-2 sr-only">
                            <div className="mx-auto max-w-7xl px-2 lg:px-8">

                                <h2 className="text-center text-md font-semibold leading-8 text-gray-900 ">
                                    <a href="https://triokzz.com/product/watchtv">Best Selective {navItem} Products Trusted By The World’s Most Innovative Teams</a>
                                </h2>
                            </div>
                        </div>
                    </div>
                    }

                </div>
            </div>
            <section class="py-5 relative">
                <div class="w-full max-w-7xl mx-auto px-4 md:px-8">
                    <div class="grid grid-cols-10 gap-8">

                        <div class="col-span-10 lg:col-span-6 w-full">
                            <div class="container mx-auto">
                                <h5 class="text-4xl text-green-500 font-culpa">Organic World</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="mx-auto max-w-2xl px-1 py-1 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8 mt-1 bg-neutral-50"
            >
                {dialog ? <ProductDialog open={dialog} onOpen={() => onOpen} cardProduct={cardProduct} onAddToCard={onAddToCard} setDialog={setDialog} /> : ""}
                {openshopping ? <ShoppingCard openshopping={openshopping} setOpenShopping={setOpenShopping} onRemove={onRemove} onCount={onCount} onOpen={onOpen} /> : ""}

                {
                    GroceryProducts && GroceryProducts.section ? GroceryProducts && GroceryProducts.section && GroceryProducts.section.map((specialProd, i) => {
                        let [keys] = Object.keys(specialProd)
                        let arr = specialProd[keys]
                        // setProdkeys(arr)
                        // console.log(arr)
                        return (<div className="mt-6  grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-4 md:grid-cols-3 xl:gap-x-8">
                            {/* <h4 class="text-2xl text-green-500 font-culpa tracking-tight ">{keys}</h4> */}
                            {arr ? arr.map((val, id) => {

                                return (
                                    <div key={val.uni_productId} className={val.freez ? "group relative cursor-not-allowed transformed opacity-25 bg-white drop-shadow-lg flex m-2" : "group relative bg-white drop-shadow-lg flex m-2"} onClick={() => onOpen(val)}>

                                        <div className=" aspect-h-1 aspect-w-1 w-1/2 overflow-hidden bg-white lg:aspect-none group-hover:opacity-75 lg:h-48">
                                            <img
                                                src={val.url}
                                                alt={val.type}
                                                style={{ height: "220px", width: "100%", objectFit: "fill", aspectRatio: "auto" }}
                                                loading="lazy"
                                                className="h-full w-full px-2 py-2 aspect-ratio-auto object-cover object-center lg:h-full lg:w-full"
                                                title={val.product_name}
                                            />
                                            {val.freez ? <span className="absolute flex text-md text-amber-400 bottom-0 left-20 -translate-x-1/2 bg-red-600   inline-block">
                                                <span className="relative text-white">Sold Out</span>
                                            </span> : <span className="absolute h-5 flex text-md text-amber-400 bottom-0 left-32 -translate-x-2/3 bg-red-600 inline-block">
                                                <span className="flex items-center justify-center text-white text-sm  bg-black w-20"> {Math.floor(Math.abs(((val.offer_prize - val.real_prize) / val.real_prize) * 100))}  <span className="text-sm items-center justify-center">% off</span></span>
                                                <div class="flex items-center justify-center">
                                                    <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                    </svg>
                                                    <p class="mr-3 text-sm font-bold text-white dark:text-white">{val.rating}</p>
                                                </div>
                                                {val.delivery_charge == "00" ? <div class="flex items-center justify-center">
                                                    <img width="48" height="48" src="https://img.icons8.com/external-flat-gradient-andi-nur-abdillah/64/external-delivery-delivery-and-logistic-flat-gradient-flat-gradient-andi-nur-abdillah-2.png" alt=" external-delivery-delivery-and-logistic-flat-gradient-flat-gradient-andi-nur-abdillah-2" className='animate-bounce' />
                                                    <p class="mr-4 text-sm font-bold text-white dark:text-white">Free</p>
                                                </div> : ""}
                                            </span>
                                            }

                                        </div>
                                        <div className="grid grid-cols-1 justify-between p-2 w-40">
                                            <div>
                                                <h3 className="text-base capitalize text-sky-600 mt-2">
                                                    <span aria-hidden="true" className="absolute inset-0" />
                                                    {val.product_name}
                                                </h3>

                                            </div>

                                            <div className=" grid grid-cols-2 justify-between drop-shadow-lg">
                                                <h3 className="text-xs  font-medium text-green">₹ {val.offer_prize} /-</h3>
                                                <div>
                                                    <p className="text-xs font-medium text-red-900 line-through">₹ {val.real_prize} /-</p>
                                                </div>

                                            </div>

                                            <div className=" grid grid-cols-2 text-center justify-between drop-shadow-lg mb-2" >
                                                <div>
                                                    <p className="text-xs font-medium text-green">{val.size}</p>
                                                </div>
                                                {!val.freez ? <div className="relative p-1 flex items-center justify-center" onClick={() => onAddToCard({
                                                    name: val.brand,
                                                    product_name: val.product_name,
                                                    price: val.offer_prize,
                                                    real_prize: val.real_prize,
                                                    id: val.id,
                                                    count: val.count,
                                                    rating: 3.9,
                                                    reviewCount: 117,
                                                    imageSrc: val.url,
                                                    imageAlt: val.brand,
                                                    colors: { name: val.color, class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
                                                    sizes: { name: val.size[0], inStock: true },
                                                    size: val.size,
                                                    uni_productId: val.uni_productId,
                                                    type: val.type,
                                                    detail: val.product_name + val.product_detail,
                                                    detail_url: val.detail_url,
                                                    delivery_charge: val.delivery_charge
                                                })}>
                                                    <a
                                                        href="#"
                                                        className="flex w-3/4 item-center justify-center rounded-md border border border-indigo-300 bg-yellow text-white px-1 py-1 text-base font-small shadow-sm hover:bg-indigo-700"
                                                    >
                                                        🛒
                                                    </a>

                                                </div> : <div className="relative p-1 flex tems-center justify-center">
                                                    <a
                                                        href="#"
                                                        className="flex w-3/4 item-center justify-center rounded-md border border border-indigo-300 bg-red-600 text-white px-1 py-1 text-base font-small shadow-sm hover:bg-indigo-700"
                                                    >
                                                        Not Available
                                                    </a>

                                                </div>}
                                            </div>
                                        </div>


                                    </div>
                                )
                            }) : ""}

                        </div>
                        )
                    })
                        : <div role="status" class="max-w-sm p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
                            <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                                <svg class="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                                    <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                                </svg>
                            </div>
                            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            <div class="flex items-center mt-4">
                                <svg class="w-10 h-10 me-3 text-gray-200 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                </svg>
                                <div>
                                    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                                    <div class="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                </div>
                            </div>
                            <span class="sr-only">Loading...</span>
                        </div>}

                {/* <h1 className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
                    {navItem} products at one place.
                </h1> */}
                {/* <h2 className="flex items-center bg-indigo-600 text-white font-semibold">Free shipping on all orders of Rs 1449 and above. All product shall be dispatched with 24-48 hours excepts on bank holiday.
                    Delivery within india is Completed withing 5-10 working days depends on location.
                </h2> */}

                {/* 
    more product */}


                {/* remove after implimentation */}
            </div >
            {/* <div className="group fixed bottom-0 right-0 top-5 p-2  flex items-end justify-end w-24 h-24 ">
                <div className="text-white shadow-xl flex items-center justify-center p-2 rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 z-50 absolute  ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="animate-bounce h-6 w-6"
                        onClick={() => scrolltobottom()} >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
            </div> */}


            <div className="w-full">
                <section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
                    {/* <section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> */}
                    <div id="tabs" className="flex justify-between">
                        <a href="/grocery/products" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M21.0847458,3.38674884 C17.8305085,7.08474576 17.8305085,10.7827427 21.0847458,14.4807396 C24.3389831,18.1787365 24.3389831,22.5701079 21.0847458,27.6548536 L21.0847458,42 L8.06779661,41.3066256 L6,38.5331279 L6,26.2681048 L6,17.2542373 L8.88135593,12.4006163 L21.0847458,2 L21.0847458,3.38674884 Z" fill="currentColor" fill-opacity="0.1"></path>
                                    <path d="M11,8 L33,8 L11,8 Z M39,17 L39,36 C39,39.3137085 36.3137085,42 33,42 L11,42 C7.6862915,42 5,39.3137085 5,36 L5,17 L7,17 L7,36 C7,38.209139 8.790861,40 11,40 L33,40 C35.209139,40 37,38.209139 37,36 L37,17 L39,17 Z" fill="currentColor"></path>
                                    <path d="M22,27 C25.3137085,27 28,29.6862915 28,33 L28,41 L16,41 L16,33 C16,29.6862915 18.6862915,27 22,27 Z" stroke="currentColor" stroke-width="2" fill="currentColor" fill-opacity="0.1"></path>
                                    <rect fill="currentColor" transform="translate(32.000000, 11.313708) scale(-1, 1) rotate(-45.000000) translate(-32.000000, -11.313708) " x="17" y="10.3137085" width="30" height="2" rx="1"></rect>
                                    <rect fill="currentColor" transform="translate(12.000000, 11.313708) rotate(-45.000000) translate(-12.000000, -11.313708) " x="-3" y="10.3137085" width="30" height="2" rx="1"></rect>
                                </g>
                            </svg>
                            <span class="sr-only">Go Organic with triokzz.  To get most trusted product. You almost be here.Triokzz always shake hands to save soil. We are selling partner.
                            </span>

                            <span className="tab tab-home block text-xs">Organic Home</span>
                        </a>
                        <a href="/list/grocery/Millets" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M14.7118754,20.0876892 L8.03575361,20.0876892 C5.82661462,20.0876892 4.03575361,18.2968282 4.03575361,16.0876892 L4.03575361,12.031922 C4.03575361,8.1480343 6.79157254,4.90780265 10.4544842,4.15995321 C8.87553278,8.5612583 8.1226025,14.3600511 10.9452499,15.5413938 C13.710306,16.6986332 14.5947501,18.3118357 14.7118754,20.0876892 Z M14.2420017,23.8186831 C13.515543,27.1052019 12.7414284,30.2811559 18.0438552,31.7330419 L18.0438552,33.4450645 C18.0438552,35.6542035 16.2529942,37.4450645 14.0438552,37.4450645 L9.90612103,37.4450645 C6.14196811,37.4450645 3.09051926,34.3936157 3.09051926,30.6294627 L3.09051926,27.813861 C3.09051926,25.604722 4.88138026,23.813861 7.09051926,23.813861 L14.0438552,23.813861 C14.1102948,23.813861 14.1763561,23.8154808 14.2420017,23.8186831 Z M20.7553776,32.160536 C23.9336213,32.1190063 23.9061943,29.4103976 33.8698747,31.1666916 C34.7935223,31.3295026 35.9925894,31.0627305 37.3154077,30.4407183 C37.09778,34.8980343 33.4149547,38.4450645 28.9036761,38.4450645 C24.9909035,38.4450645 21.701346,35.7767637 20.7553776,32.160536 Z" fill="currentColor" opacity="0.1"></path>
                                    <g transform="translate(2.000000, 3.000000)">
                                        <path d="M8.5,1 C4.35786438,1 1,4.35786438 1,8.5 L1,13 C1,14.6568542 2.34314575,16 4,16 L13,16 C14.6568542,16 16,14.6568542 16,13 L16,4 C16,2.34314575 14.6568542,1 13,1 L8.5,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M4,20 C2.34314575,20 1,21.3431458 1,23 L1,27.5 C1,31.6421356 4.35786438,35 8.5,35 L13,35 C14.6568542,35 16,33.6568542 16,32 L16,23 C16,21.3431458 14.6568542,20 13,20 L4,20 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M23,1 C21.3431458,1 20,2.34314575 20,4 L20,13 C20,14.6568542 21.3431458,16 23,16 L32,16 C33.6568542,16 35,14.6568542 35,13 L35,8.5 C35,4.35786438 31.6421356,1 27.5,1 L23,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M34.5825451,33.4769886 L38.3146092,33.4322291 C38.8602707,33.4256848 39.3079219,33.8627257 39.3144662,34.4083873 C39.3145136,34.4123369 39.3145372,34.4162868 39.3145372,34.4202367 L39.3145372,34.432158 C39.3145372,34.9797651 38.8740974,35.425519 38.3265296,35.4320861 L34.5944655,35.4768456 C34.048804,35.4833899 33.6011528,35.046349 33.5946085,34.5006874 C33.5945611,34.4967378 33.5945375,34.4927879 33.5945375,34.488838 L33.5945375,34.4769167 C33.5945375,33.9293096 34.0349773,33.4835557 34.5825451,33.4769886 Z" fill="currentColor" transform="translate(36.454537, 34.454537) rotate(-315.000000) translate(-36.454537, -34.454537) "></path>
                                        <circle stroke="currentColor" stroke-width="2" cx="27.5" cy="27.5" r="7.5"></circle>
                                    </g>
                                </g>
                            </svg>
                            <span class="sr-only">If you really want to buy organic millets this is best platform for you. Triokzz Marketing Team roam around India and get best products for you. Shop Now.</span>
                            <span className="tab tab-kategori block text-xs">Millets</span>
                        </a>
                        <a href="/list/grocery/Spice%20Masale" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M20.5890101,0.254646884 C12.8696785,5.50211755 8.0025785,14.258415 14.1941217,18.8708225 C23.16683,25.5550669 13.3362326,40.2698884 33.1021758,38.4149164 C29.6814884,40.8311956 25.5065164,42.2507054 21,42.2507054 C9.40202025,42.2507054 0,32.8486852 0,21.2507054 C0,9.79003409 9.18071714,0.473634138 20.5890101,0.254646884 Z" fill="currentColor" opacity="0.1"></path>
                                    <path d="M25.9500282,20.3643496 L22.4308312,38.2677802 C22.3775703,38.5387376 22.1147395,38.7152155 21.8437821,38.6619546 C21.6570955,38.6252584 21.507413,38.4857901 21.4576354,38.3021581 L16.5951895,20.3643496 L20.099732,4.44663907 C20.1385204,4.27046145 20.2692032,4.12883813 20.4417012,4.07604096 C20.7057521,3.99522179 20.9853245,4.14376046 21.0661436,4.40781135 L25.9500282,20.3643496 Z M21.3022963,22.2852638 C22.4068658,22.2852638 23.3022963,21.3898333 23.3022963,20.2852638 C23.3022963,19.1806943 22.4068658,18.2852638 21.3022963,18.2852638 C20.1977268,18.2852638 19.3022963,19.1806943 19.3022963,20.2852638 C19.3022963,21.3898333 20.1977268,22.2852638 21.3022963,22.2852638 Z" fill="currentColor" transform="translate(21.272609, 20.629524) rotate(-315.000000) translate(-21.272609, -20.629524) "></path>
                                    <circle stroke="currentColor" stroke-width="2" cx="21" cy="21" r="20"></circle>
                                </g>
                            </svg>
                            <span class="sr-only">Flavore your spicy world with Triorz spice masale. </span>

                            <span className="tab tab-explore block text-xs">Spice Factory</span>
                        </a>
                        <a href="#" onClick={() => onshoppingRender()} className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
                            <svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M14.7118754,20.0876892 L8.03575361,20.0876892 C5.82661462,20.0876892 4.03575361,18.2968282 4.03575361,16.0876892 L4.03575361,12.031922 C4.03575361,8.1480343 6.79157254,4.90780265 10.4544842,4.15995321 C8.87553278,8.5612583 8.1226025,14.3600511 10.9452499,15.5413938 C13.710306,16.6986332 14.5947501,18.3118357 14.7118754,20.0876892 Z M14.2420017,23.8186831 C13.515543,27.1052019 12.7414284,30.2811559 18.0438552,31.7330419 L18.0438552,33.4450645 C18.0438552,35.6542035 16.2529942,37.4450645 14.0438552,37.4450645 L9.90612103,37.4450645 C6.14196811,37.4450645 3.09051926,34.3936157 3.09051926,30.6294627 L3.09051926,27.813861 C3.09051926,25.604722 4.88138026,23.813861 7.09051926,23.813861 L14.0438552,23.813861 C14.1102948,23.813861 14.1763561,23.8154808 14.2420017,23.8186831 Z M20.7553776,32.160536 C23.9336213,32.1190063 23.9061943,29.4103976 33.8698747,31.1666916 C34.7935223,31.3295026 35.9925894,31.0627305 37.3154077,30.4407183 C37.09778,34.8980343 33.4149547,38.4450645 28.9036761,38.4450645 C24.9909035,38.4450645 21.701346,35.7767637 20.7553776,32.160536 Z" fill="currentColor" opacity="0.1"></path>
                                    <g transform="translate(2.000000, 3.000000)">
                                        <path d="M8.5,1 C4.35786438,1 1,4.35786438 1,8.5 L1,13 C1,14.6568542 2.34314575,16 4,16 L13,16 C14.6568542,16 16,14.6568542 16,13 L16,4 C16,2.34314575 14.6568542,1 13,1 L8.5,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M4,20 C2.34314575,20 1,21.3431458 1,23 L1,27.5 C1,31.6421356 4.35786438,35 8.5,35 L13,35 C14.6568542,35 16,33.6568542 16,32 L16,23 C16,21.3431458 14.6568542,20 13,20 L4,20 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M23,1 C21.3431458,1 20,2.34314575 20,4 L20,13 C20,14.6568542 21.3431458,16 23,16 L32,16 C33.6568542,16 35,14.6568542 35,13 L35,8.5 C35,4.35786438 31.6421356,1 27.5,1 L23,1 Z" stroke="currentColor" stroke-width="2"></path>
                                        <path d="M34.5825451,33.4769886 L38.3146092,33.4322291 C38.8602707,33.4256848 39.3079219,33.8627257 39.3144662,34.4083873 C39.3145136,34.4123369 39.3145372,34.4162868 39.3145372,34.4202367 L39.3145372,34.432158 C39.3145372,34.9797651 38.8740974,35.425519 38.3265296,35.4320861 L34.5944655,35.4768456 C34.048804,35.4833899 33.6011528,35.046349 33.5946085,34.5006874 C33.5945611,34.4967378 33.5945375,34.4927879 33.5945375,34.488838 L33.5945375,34.4769167 C33.5945375,33.9293096 34.0349773,33.4835557 34.5825451,33.4769886 Z" fill="currentColor" transform="translate(36.454537, 34.454537) rotate(-315.000000) translate(-36.454537, -34.454537) "></path>
                                        <circle stroke="currentColor" stroke-width="2" cx="27.5" cy="27.5" r="7.5"></circle>
                                    </g>
                                </g>
                            </svg>
                            <span class="sr-only">Save your amazing power looks items and flip to cart now</span>

                            <span className="tab tab-whishlist block text-xs">Whishlist</span>
                        </a>

                    </div>
                </section>
            </div>
            <footer className=" relative 
             inset-x-0 bottom-0 bg-gray-900 rounded-lg shadow dark:bg-gray-900 m-2">
                <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <a href="#" className="flex items-center mb-4 sm:mb-0">
                            <img src={Logo} className="h-14 w-25 mr-3" alt="shoppp Logo" title="triokzz" />
                            <span class="sr-only">Save your amazing power looks items, Healthy products only on triokzz. flip to cart and get millets, dairy products, healthy snacks and more at one place -Triokzz, JiGari, Ted baker, fasion</span>
                        </a>
                    </div>
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <a href="#" class="flex items-center mb-4 ml-4 sm:mb-0 text-white">
                            Products
                        </a>
                        <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white">
                            <li class="mr-4 hover:underline md:mr-6 " >
                                <a href="/list/grocery/Organic%20Groceries">Grocery</a>
                            </li>
                            <li class="mr-4 hover:underline md:mr-6 " >
                                <a href="/list/grocery/Healty%20Snacks">Healty Snacks</a>
                            </li>
                            <li class="mr-4 hover:underline md:mr-6 " >
                                <a href="/list/grocery/Millets">Millets</a>
                            </li>
                            <li class="mr-4 hover:underline md:mr-6">
                                <a href="/list/grocery/Baby%20And%20Personal%20Care">Baby Care & Personal Care</a>
                            </li>
                            <li class="mr-4 hover:underline md:mr-6">
                                <a href="/list/grocery/Dairy%20Products">Dairy</a>
                            </li>
                            <li class="mr-4 hover:underline md:mr-6">
                                <a href="/list/grocery/Aayurvedic%20Products">Ayurvedic</a>
                            </li>
                            <li class="mr-4 hover:underline md:mr-6">
                                <a href="/list/grocery/Agricultural%20Products">Agricultural</a>
                            </li>
                            <li class="mr-4 hover:underline md:mr-6 ">
                                <a href="/contact" class="hover:underline">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <a href="#" class="flex items-center mb-4 ml-4 sm:mb-0 text-white">
                            Available Products
                        </a>
                        {
                            GroceryProducts && GroceryProducts.section.map((specialProd, i) => {
                                let [keys] = Object.keys(specialProd)
                                let arr = specialProd[keys]
                                // setProdkeys(arr)
                                // console.log(arr)
                                return (<ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white">
                                    {arr ? arr.map((val, id) => {
                                        let somthing = addtocard && addtocard.find((values) => {

                                            return values.id == val.id
                                        })
                                        if (!val.freez && somthing == undefined) {
                                            return (<li class="mr-4 hover:underline md:mr-6 ">
                                                <a href={`https://www.triokzz.com/organic+grocery/${navItem}/${val.type}+${val.id}`} >{val.color} {val.type}</a>
                                                <span class="sr-only">{val.product_detail}</span>
                                            </li>)
                                        }
                                    }) : ""
                                    }
                                </ul>)
                            }
                            )
                        }

                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />

                    <div class="sm:flex sm:items-center sm:justify-between">
                        <span className="block text-sm text-white sm:text-center dark:text-gray-400">© 2023 Developed By <a href="https://www.triokzz.com/contact" className="hover:underline">Triokzz</a>. All Rights Reserved.</span>
                        <div class="flex mt-4 sm:justify-center sm:mt-0">
                            <a href="https://www.facebook.com/profile.php?id=61553460826080&mibexid=ZbWKwL" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                    <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd" />
                                </svg>
                                <span class="sr-only">Experience the joy with Healthy  products every morning.</span>
                            </a>
                            <a href="https://www.instagram.com/triokzz?igsh=MWUzM3dweTNyY2NpeA==" class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 21 16">
                                    <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                                </svg>
                                <span class="sr-only">Follow Our Instagram Page And Get More exiting offers and coupan code.</span>
                            </a>
                            <a href="#" class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                                    <path fill-rule="evenodd" d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z" clip-rule="evenodd" />
                                </svg>
                                <span class="sr-only">Twitter page</span>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div >

    )
}

export default GroceryList;