import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Logo from './../assist/triokzzlohgo.svg';
import './Celebration.css'
// import { app, auth } from "./firebase";
// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB8YeifobKfJJSO3nselAILteDgp4iIAMA",
    authDomain: "loginwithphone-9fb64.firebaseapp.com",
    projectId: "loginwithphone-9fb64",
    storageBucket: "loginwithphone-9fb64.appspot.com",
    messagingSenderId: "395072549821",
    appId: "1:395072549821:web:4f59770be42fda341a98d4",
    measurementId: "G-RWZEXW6VMP"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
// const analytics = getAnalytics(app);

const Registration = (props) => {
    const navigate = useNavigate()
    const [mynumber, setnumber] = useState("");
    const [otp, setotp] = useState("");
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState("");
    const [JWebToken, setJWebToken] = useState({})
    const [otpresult, setOtpResult] = useState()
    // console.log("mynumber ", mynumber)
    // Sent OTP
    const signin = () => {
        if (mynumber === "" || mynumber.length < 10) return;
        // firebase.auth().settings.appVerificationDisabledForTesting = true;
        let verify = new firebase.auth.RecaptchaVerifier(
            'recaptcha-container');

        // console.log("mynumber ", mynumber, verify)
        firebase.auth().signInWithPhoneNumber(`+91${mynumber}`, verify)
            .then((result) => {
                setfinal(result);
                alert("OTP Sent To Your Mobile Number");
                setshow(true);
            })
            .catch((err) => {
                alert(err);
                // window.location.reload();
            });
    };

    // Validate OTP
    const ValidateOtp = () => {
        if (otp === null || final === null) return;
        final
            .confirm(otp)
            .then((result) => {
                // success
                // console.log("result", result && result.user && result.user.multiFactor && result.user.multiFactor.user)
                navigate("/paymentgetway")
                sessionStorage.setItem('PHONEOTPINFO', JSON.stringify(result && result.user && result.user.multiFactor && result.user.multiFactor.user))
                firebase.auth().signOut().then(() => {
                    // Sign-out successful.
                }).catch((error) => {
                    // An error happened.
                })
            })
            .catch((err) => {
                alert("Please Enter Correct OTP");

            });
    };


    const handlecallbackresponse = (response) => {

        let jwtdecode = jwt_decode(response.credential)
        setJWebToken(jwtdecode)
        if (jwtdecode.email_verified) {
            navigate("/paymentgetway", { state: jwtdecode })
        }
        // console.log("response", jwtdecode)
    }
    useEffect(() => {
        /*globle google*/
        window && window.google && window.google.accounts.id && window.google.accounts.id.initialize({
            client_id: "868675978272-beqkfbkjd6rmnjbtt1kil8kuel25k2gf.apps.googleusercontent.com",
            auto_select: true,
            itp_support: false,
            callback: handlecallbackresponse
        })
        window && window.google && window.google.accounts.id && window.google.accounts.id.renderButton(
            document.getElementById('signindiv'),
            { theme: "outline", size: 'large', text: 'center' }
        )
        window && window.google && window.google.accounts.id && window.google.accounts.id.prompt()
        console.log("HELLO LOGGER")
    }, [])

    return (
        <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">

            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                {/* <form class="space-y-6" action="#" method="POST"> */}
                <center>

                    <p>{JWebToken.name}</p>
                    <div style={{ display: show ? "none" : "block" }} class="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                            className="mx-auto h-14 w-25"
                            src={Logo}
                            alt="shoptodecore"
                        />
                        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in with google or with your phone number </h2>
                        <div id='signindiv'>

                        </div>
                    </div>
                    <hr></hr>
                    <div
                        style={{ display: show ? "none" : "block" }}
                    >
                        <label for="user" class="mt-2 block text-sm font-medium leading-6 text-gray-900">OR</label>
                        <div class="flex items-center justify-center mt-2">
                            <button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button">
                                <svg class="w-5 h-5" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="256" fill="#f0f0f0" r="256" /><path d="m256 0c-101.494 0-189.19 59.065-230.598 144.696h461.195c-41.407-85.631-129.104-144.696-230.597-144.696z" fill="#ff9811" /><path d="m256 512c101.493 0 189.19-59.065 230.598-144.696h-461.196c41.408 85.631 129.104 144.696 230.598 144.696z" fill="#6da544" /><circle cx="256" cy="256" fill="#0052b4" r="89.043" /><circle cx="256" cy="256" fill="#f0f0f0" r="55.652" /><path d="m256 187.326 17.169 38.938 42.304-4.601-25.136 34.337 25.136 34.337-42.304-4.601-17.169 38.938-17.169-38.938-42.304 4.6 25.136-34.336-25.136-34.337 42.304 4.601z" fill="#0052b4" /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /></svg>
                                +91 <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" /></svg>
                            </button>
                            <div class="relative">
                                <input
                                    name="phone"
                                    value={mynumber}
                                    onChange={(e) => {
                                        setnumber(e.target.value);
                                    }}

                                    type="string" autoComplete="phone" id="phone-input" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="8585855582" required />
                            </div>
                        </div>

                        <button
                            type="button"
                            className="mt-2 p-1 items-center justify-center font-medium bg-indigo-600 text-white hover:text-indigo-500"
                            onClick={() => signin()}>
                            Send OTP
                        </button>
                        <br />

                        <br />
                        <div id="recaptcha-container"></div>
                    </div>

                    <div
                        style={{
                            display: show ? "block" : "none",
                        }}
                    >
                        <input
                            type="text"
                            placeholder={"Enter your OTP"}
                            onChange={(e) => {
                                setotp(e.target.value);
                            }}
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <br />
                        <br />
                        <button
                            type="button"
                            className=" p-1 font-medium bg-indigo-600 text-white hover:text-indigo-500"
                            onClick={() => ValidateOtp()}>
                            Verify
                        </button>
                    </div>

                    {/* <div>
                            <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign in</button>
                        </div> */}
                </center>
                {/* </form> */}
            </div>
        </div>
    )
}

export default Registration;